import React, { useState } from "react";
import { View } from "react-native";
import { observer } from "mobx-react-lite";
import { EnhancedSegment } from "../../../services/analysis/GeoJsonEnhancer";
import { RaceRowTable } from "./RaceRowTable";
import { RaceTableHeader } from "./RaceTableHeader";
import { scrollTable } from "../../../theme/view-style";

export interface RaceTableProps {
  segments: EnhancedSegment[];
  showAll: boolean;
  onSegmentSelected: (segment: EnhancedSegment) => void;
}

export const RaceTable = observer(function RaceTable(props: RaceTableProps) {
  const { segments, showAll, onSegmentSelected } = props;
  const [showWaypointsOnly, _setShowWaypointsOnly] = useState<boolean>(false);

  return (
    <View style={scrollTable}>
      <RaceTableHeader />
      <RaceRowTable
        segments={segments}
        showAll={showAll}
        onSegmentSelected={onSegmentSelected}
        showWaypointsOnly={showWaypointsOnly}
      />
    </View>
  );
});
