import { Distance } from "../domain/race-calculator/Distance";
import { Time } from "../domain/race-calculator/Time";
import { TimeConverter } from "../domain/race-calculator/TimeConverter";

export enum Range {
  EASY = "easy",
  MEDIUM = "medium",
  HARD = "hard",
  NONE = "none",
}

interface Interval {
  minutes: number;
  percentage: number;
}

const intervals: Interval[] = [
  { minutes: 12, percentage: 100 },
  { minutes: 20, percentage: 93 },
  { minutes: 40, percentage: 85 },
  { minutes: 80, percentage: 75 },
  { minutes: 200, percentage: 68 },
  { minutes: 320, percentage: 60 },
  { minutes: 400, percentage: 50 },
  { minutes: 2000, percentage: 30 },
];

export class MAS {
  static getPercentFromTime(time: Time): number {
    const timeInMinutes = TimeConverter.toSeconds(time) / 60;

    if (timeInMinutes <= intervals[0].minutes) {
      return intervals[0].percentage;
    }

    if (timeInMinutes >= intervals[intervals.length - 1].minutes) {
      return intervals[intervals.length - 1].percentage;
    }

    for (let i = 0; i < intervals.length - 1; i++) {
      const current = intervals[i];
      const next = intervals[i + 1];

      if (timeInMinutes >= current.minutes && timeInMinutes <= next.minutes) {
        const ratio =
          (timeInMinutes - current.minutes) / (next.minutes - current.minutes);
        return (
          current.percentage - ratio * (current.percentage - next.percentage)
        );
      }
    }

    return intervals[intervals.length - 1].percentage;
  }

  static getPercentFromDistance(mas: number, distance: Distance): number {
    const time = TimeConverter.toDomain(distance.getInSmallSize(), mas);
    return this.getPercentFromTime(time);
  }

  static percentColor(
    percentFromSpeed: number | null,
    idealPercent: number | null,
  ): Range {
    if (percentFromSpeed === null || idealPercent === null) {
      return Range.NONE;
    }

    const lowerBound = idealPercent - 4;
    const upperBound = idealPercent + 4;
    const mediumLowerBound = idealPercent - 2;
    const mediumUpperBound = idealPercent + 2;

    if (percentFromSpeed >= lowerBound && percentFromSpeed < mediumLowerBound) {
      return Range.EASY;
    } else if (
      percentFromSpeed >= mediumLowerBound &&
      percentFromSpeed <= mediumUpperBound
    ) {
      return Range.MEDIUM;
    } else if (
      percentFromSpeed > mediumUpperBound &&
      percentFromSpeed <= upperBound
    ) {
      return Range.HARD;
    }

    return Range.NONE;
  }
}
