import React from "react";
import { observer } from "mobx-react-lite";
import { spacing } from "../../theme";
import { Sport } from "../../models/sport/sport";
import { translate } from "swunitch-i18n";
import { View } from "react-native";
import { CardToggle } from "../CardToggle";

export const ObjectiveModeSelector = observer(
  function ObjectiveModeSelector(props: { sport: Sport }) {
    const { sport } = props;

    const selectMode = (value: boolean) => {
      if (value) {
        sport.updateIsTimeObjective(true);
      } else {
        sport.updateIsTimeObjective(false);
      }
    };

    return (
      <View style={{ gap: spacing.small }}>
        <CardToggle
          title={translate("segmented.timeObjective")}
          description={translate("toast.timeObjective")}
          value={sport.isTimeObjective}
          onToggle={() => selectMode(true)}
        />
        <CardToggle
          title={translate("segmented.distanceObjective")}
          description={translate("toast.distanceObjective")}
          value={!sport.isTimeObjective}
          onToggle={() => selectMode(false)}
        />
      </View>
    );
  },
);
