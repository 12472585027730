import React, { FC, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  AppStackParamList,
  AppStackScreenProps,
  AppTabScreenProps,
} from "../navigators";
import { CompositeScreenProps, useNavigation } from "@react-navigation/native";
import { useBrandTheme } from "../theme/use-brand-theme";
import { BACKGROUND_CONTAINER, CONTAINER } from "../theme/view-style";
import { spacing } from "../theme";
import { StyleSheet, View } from "react-native";
import {
  Card,
  imageRegistry,
  Screen,
  Text,
  useSafeAreaInsetPadding,
} from "../components";
import { ImageBackground } from "expo-image";
import { AddCourse } from "./run/AddCourse";
import { ListRaces } from "./run/ListRaces";
import { translate } from "swunitch-i18n";
import { StackNavigationProp } from "@react-navigation/stack";
import { useStores } from "../models";
import { RaceType } from "../models/race/race-type";
import { SportsTab } from "../components/segmented/SportsTab";
import { useOrientation } from "../utils/orientation-style";
import { SportsComponent } from "../components/sports/SportsComponent";

export const ProfileScreen: FC<
  CompositeScreenProps<AppTabScreenProps<"profile">, AppStackScreenProps<"app">>
> = observer(function ProfileScreen() {
  const { globalStore } = useStores();
  const theme = useBrandTheme();
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>();
  const [type, setType] = useState<RaceType | "all">(RaceType.Run);
  const normalizedType = type === "all" ? RaceType.Run : type;
  const deviceOrientation = useOrientation();
  const insetPadding = useSafeAreaInsetPadding(["top"]);

  function createLocalRace() {
    const race = globalStore.createLocalRace(normalizedType);
    navigation.navigate("edit-race", { race });
  }

  return (
    <Screen preset="scroll">
      <View
        style={{
          ...BACKGROUND_CONTAINER(theme),
          gap: spacing.large,
        }}
      >
        <View
          style={[
            CONTAINER(deviceOrientation),
            { paddingVertical: spacing.small },
            insetPadding,
          ]}
        >
          <ImageBackground
            source={imageRegistry.blueBackground}
            style={{
              ...StyleSheet.absoluteFillObject,
            }}
          />

          <SportsTab
            onTypeChange={(t) => {
              setType(t);
            }}
            type={type}
            hideAll={true}
          />
        </View>

        <View>
          <View style={CONTAINER(deviceOrientation)}>
            <Text preset="heading" tx="profileScreen.title" />
          </View>

          <Card>
            <ImageBackground
              source={imageRegistry.blueBackground}
              style={{
                ...StyleSheet.absoluteFillObject,
              }}
            />
            <View
              style={{
                paddingTop: spacing.medium,
                paddingBottom: spacing.tiny,
              }}
            >
              <SportsComponent
                type={type}
                setType={() => null}
                hideMoreOptions={false}
                expandAdvanced={true}
              />
            </View>
          </Card>
        </View>

        <AddCourse onPress={createLocalRace} type={normalizedType} />

        <ListRaces
          title={translate("profileScreen.localCourses")}
          races={globalStore.localRaces.filter(
            (r) => r.type === normalizedType,
          )}
          computeTime={true}
          displayMap={false}
        />
      </View>
    </Screen>
  );
});
