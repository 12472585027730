import { ViewStyle } from "react-native";
import {
  addOrientationChangeListener,
  getOrientationAsync,
  Orientation,
  removeOrientationChangeListener,
} from "expo-screen-orientation";
import { Platform } from "expo-modules-core";
import { useEffect, useState } from "react";

export type DEVICE_ORIENTATION = "portrait" | "landscape";

export const landscapeStyle =
  (deviceOrientation: DEVICE_ORIENTATION) =>
  (landscapeStyle: ViewStyle): ViewStyle => {
    const isLandscape = deviceOrientation === "landscape";

    return isLandscape ? landscapeStyle : {};
  };

export const portraitStyle =
  (deviceOrientation: DEVICE_ORIENTATION) =>
  (portraitStyle: ViewStyle): ViewStyle => {
    const isPortrait = deviceOrientation === "portrait";

    return isPortrait ? portraitStyle : {};
  };

export function useOrientation(): DEVICE_ORIENTATION {
  const isOnWeb = Platform.OS === "web";
  const [orientation, setOrientation] = useState<Orientation>(
    Orientation.LANDSCAPE_RIGHT,
  );

  useEffect(() => {
    if (isOnWeb) {
      return;
    }

    const subscription = addOrientationChangeListener(({ orientationInfo }) => {
      setOrientation(orientationInfo.orientation);
    });

    (async () => {
      const orientation = await getOrientationAsync();
      setOrientation(orientation);
    })();

    return () => {
      removeOrientationChangeListener(subscription);
    };
  }, []);

  if (isOnWeb) {
    return "portrait";
  }

  if (
    orientation &&
    [Orientation.LANDSCAPE_LEFT, Orientation.LANDSCAPE_RIGHT].includes(
      orientation,
    )
  ) {
    return "landscape";
  }
  return "portrait";
}
