import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import { AppStackScreenProps, AppTabScreenProps } from "../../navigators";
import { CompositeScreenProps } from "@react-navigation/native";
import { View } from "react-native";
import { Card, Screen, Text } from "../../components";
import {
  BACKGROUND_CONTAINER,
  CENTER_MIDDLE,
  CONTAINER,
} from "../../theme/view-style";
import { AppDarkTheme, spacing } from "../../theme";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { useOrientation } from "../../utils/orientation-style";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { translate, TxKeyPath } from "swunitch-i18n";
import { RaceType } from "../../models/race/race-type";
import { Tag } from "../../components/Tag";

interface TrainingScreenItem {
  title: TxKeyPath;
  description: TxKeyPath;
  navigateTo: () => void;
  types: RaceType[];
}

export const TrainingScreen: FC<
  CompositeScreenProps<
    AppTabScreenProps<"training">,
    AppStackScreenProps<"app">
  >
> = observer(function TrainingScreen({ navigation }) {
  const theme = useBrandTheme();
  const deviceOrientation = useOrientation();

  const items: TrainingScreenItem[] = [
    {
      title: "masTable.title",
      description: "masTable.descriptionShort",
      navigateTo: () => navigation.navigate("mas-table"),
      types: [RaceType.Run],
    },
    {
      title: "speedTable.title",
      description: "speedTable.descriptionShort",
      navigateTo: () => navigation.navigate("speed-table"),
      types: [RaceType.Run, RaceType.Bike, RaceType.Swim],
    },
  ];

  return (
    <Screen preset="scroll">
      <View style={BACKGROUND_CONTAINER(theme)}>
        <View
          style={{
            ...CONTAINER(deviceOrientation),
            paddingTop: spacing.medium,
          }}
        >
          <Text preset="heading" tx="trainingScreen.myTools" />
        </View>

        {items.map((item, index) => (
          <Card
            key={index}
            paddingVertical={true}
            paddingHorizontal={true}
            onPress={item.navigateTo}
          >
            <View style={CENTER_MIDDLE}>
              <View style={{ flex: 1 }}>
                <View style={[CENTER_MIDDLE, { gap: spacing.tiny }]}>
                  <Text preset="formLabel">{translate(item.title)}</Text>

                  <View style={[CENTER_MIDDLE, { gap: spacing.micro }]}>
                    {item.types.map((type, index) => (
                      <Tag key={index} style={{}}>
                        <Text
                          preset="bold"
                          size="xxs"
                          style={{
                            textTransform: "capitalize",
                            color: AppDarkTheme.colors.text,
                          }}
                        >
                          {type === RaceType.Run && (
                            <MaterialCommunityIcons name="run-fast" />
                          )}
                          {type === RaceType.Bike && (
                            <MaterialCommunityIcons name="bike-fast" />
                          )}
                          {type === RaceType.Swim && (
                            <MaterialCommunityIcons name="swim" />
                          )}
                        </Text>
                      </Tag>
                    ))}
                  </View>
                </View>

                {item.description ? (
                  <Text
                    size="xs"
                    style={{ marginTop: spacing.extraSmall }}
                    tx={item.description}
                  />
                ) : null}
              </View>

              <MaterialCommunityIcons
                name="chevron-right-circle"
                size={30}
                color={theme.colors.primary}
              />
            </View>
          </Card>
        ))}
      </View>
    </Screen>
  );
});
