import React, { FC, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../models";
import { CustomDistanceCalculators } from "../../components/race-calculator/CustomDistanceCalculator";
import { Sport } from "../../models/sport/sport";
import { AppStackScreenProps } from "../../navigators";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { BACKGROUND_CONTAINER, CONTAINER } from "../../theme/view-style";
import { spacing } from "../../theme";
import { StyleSheet, useWindowDimensions, View } from "react-native";
import { ImageBackground } from "expo-image";
import { SportsTab } from "../../components/segmented/SportsTab";
import { useOrientation } from "../../utils/orientation-style";
import { RaceType } from "../../models/race/race-type";
import { SportsComponent } from "../../components/sports/SportsComponent";
import { imageRegistry, Screen } from "../../components";

export const SpeedTableScreen: FC<AppStackScreenProps<"speed-table">> =
  observer(function SpeedTableScreen() {
    const { globalStore } = useStores();
    const [type, setType] = useState<RaceType | "all">(RaceType.Run);
    const sport: Sport = useMemo(() => {
      const normalizedType = type === "all" ? RaceType.Run : type;
      return globalStore.getSportByRaceType(normalizedType);
    }, [type]);
    const deviceOrientation = useOrientation();
    const theme = useBrandTheme();
    const layout = useWindowDimensions();
    return (
      <Screen preset="scroll">
        <View
          style={{
            ...BACKGROUND_CONTAINER(theme),
            gap: spacing.large,
          }}
        >
          <View style={{ paddingVertical: spacing.small }}>
            <ImageBackground
              source={imageRegistry.blueBackground}
              style={{
                ...StyleSheet.absoluteFillObject,
              }}
            />

            <View style={CONTAINER(deviceOrientation)}>
              <SportsTab
                onTypeChange={(t) => {
                  setType(t);
                }}
                type={type}
              />
            </View>

            <View
              style={
                type === "all"
                  ? {
                      height: layout.height / 1.5,
                      justifyContent: "center",
                    }
                  : {}
              }
            >
              <SportsComponent
                type={type}
                setType={setType}
                hideMoreOptions={true}
              />
            </View>
          </View>

          {type === "all" ? null : <CustomDistanceCalculators sport={sport} />}
        </View>
      </Screen>
    );
  });
