import {
  BIG_CONVERTER,
  BIG_INPUT,
  BIG_INPUT_TEXT,
  BOTTOM_SHEET_UNIT_TEXT,
  CENTER_MIDDLE,
  INPUT_SELECTED,
  INPUT_SMALL,
  PRIMARY_TEXT,
  UNIT_DISPOSITION,
} from "../../../theme/view-style";
import React, { useCallback, useState } from "react";
import { TimeConverter } from "../../../domain/race-calculator/TimeConverter";
import { TextStyle, TouchableOpacity, View } from "react-native";
import { Time } from "../../../domain/race-calculator/Time";
import { debounce } from "lodash";
import { Converter } from "./utils/Converter";
import { Text } from "../../Text";
import { Dashes } from "../../Dashes";
import { translate } from "swunitch-i18n";
import { useBrandTheme } from "../../../theme/use-brand-theme";
import { observer } from "mobx-react-lite";

interface TimeDisplayParams {
  time: Time;
  onTimeChange: (time: Time) => void;
  textColor?: string | null;
  backgroundColor?: string | null;
  layout?: "big" | "small";
  disabled?: boolean;
  onInEdition?: (inEdition: boolean) => void;
}

export const TimeEdition = observer(function SportTimeEdition(
  props: TimeDisplayParams,
) {
  const {
    time,
    onTimeChange,
    textColor,
    backgroundColor,
    layout = "small",
    disabled = false,
    onInEdition = () => null,
  } = props;
  const [editable, setEditable] = useState(false);
  const theme = useBrandTheme();

  const TouchView = (
    disabled ? View : TouchableOpacity
  ) as typeof TouchableOpacity;
  const textColorStyle: TextStyle = textColor ? { color: textColor } : {};
  const backgroundColorStyle: TextStyle = backgroundColor
    ? { backgroundColor }
    : {};
  const inputStyle: TextStyle =
    layout === "big"
      ? { ...BIG_CONVERTER, ...textColorStyle }
      : {
          ...INPUT_SMALL(theme),
          ...PRIMARY_TEXT(theme),
          maxWidth: 35,
          ...textColorStyle,
        };
  const unitStyle =
    layout === "big"
      ? { ...BOTTOM_SHEET_UNIT_TEXT, ...textColorStyle }
      : { ...PRIMARY_TEXT(theme), ...textColorStyle };

  const inEditionContainerStyle: any =
    layout === "big"
      ? [BIG_INPUT(theme), INPUT_SELECTED, backgroundColorStyle]
      : [];

  const setEditableSlowly = useCallback(
    debounce((edit: boolean) => {
      setEditable(edit);
      onInEdition(edit);
    }, 10),
    [],
  );

  return (
    <View>
      <View style={layout === "big" ? UNIT_DISPOSITION : {}}>
        {editable && !disabled ? (
          <View
            style={{
              ...CENTER_MIDDLE,
            }}
          >
            <View style={inEditionContainerStyle}>
              <Converter
                autoFocus={false}
                formattedValue={time.days.format()}
                onValueChange={(value) =>
                  onTimeChange(
                    new Time({
                      days: parseInt(value) || 0,
                      hours: time.hours.value,
                      minutes: time.minutes.value,
                      seconds: time.seconds.value,
                    }),
                  )
                }
                mask={[/\d/, /\d/]}
                style={inputStyle}
                onBlur={() => setEditableSlowly(false)}
                onFocus={() => setEditableSlowly(true)}
              />
            </View>
            <Text style={unitStyle}>{translate("units.day")} </Text>

            <View style={inEditionContainerStyle}>
              <Converter
                autoFocus={true}
                formattedValue={time.hours.format()}
                onValueChange={(value) =>
                  onTimeChange(
                    new Time({
                      days: time.days.value,
                      hours: parseInt(value) || 0,
                      minutes: time.minutes.value,
                      seconds: time.seconds.value,
                    }),
                  )
                }
                mask={[/\d/, /\d/]}
                style={inputStyle}
                onBlur={() => setEditableSlowly(false)}
                onFocus={() => setEditableSlowly(true)}
              />
            </View>
            <Text style={unitStyle}>h </Text>

            <View style={inEditionContainerStyle}>
              <Converter
                autoFocus={false}
                formattedValue={time.minutes.format()}
                onValueChange={(value) =>
                  onTimeChange(
                    new Time({
                      days: time.days.value,
                      hours: time.hours.value,
                      minutes: parseInt(value) || 0,
                      seconds: time.seconds.value,
                    }),
                  )
                }
                mask={[/\d/, /\d/]}
                style={inputStyle}
                onBlur={() => setEditableSlowly(false)}
                onFocus={() => setEditableSlowly(true)}
              />
            </View>
            <Text style={unitStyle}>m </Text>

            <View style={inEditionContainerStyle}>
              <Converter
                autoFocus={false}
                formattedValue={time.seconds.format()}
                onValueChange={(value) =>
                  onTimeChange(
                    new Time({
                      days: time.days.value,
                      hours: time.hours.value,
                      minutes: time.minutes.value,
                      seconds: parseInt(value) || 0,
                    }),
                  )
                }
                mask={[/\d/, /\d/]}
                style={inputStyle}
                onBlur={() => setEditableSlowly(false)}
                onFocus={() => setEditableSlowly(true)}
              />
            </View>
            <Text style={unitStyle}>s</Text>
          </View>
        ) : (
          <TouchView
            style={
              layout === "big"
                ? [
                    BIG_INPUT(theme) as any,
                    disabled ? INPUT_SELECTED : null,
                    backgroundColorStyle,
                  ]
                : {}
            }
            onPress={() => {
              setEditable(true);
              onInEdition(true);
            }}
          >
            <Text
              style={{
                ...(layout === "big" ? BIG_INPUT_TEXT : PRIMARY_TEXT(theme)),
                ...textColorStyle,
              }}
              testID="humanTime"
            >
              {TimeConverter.toHuman(time, true)}
            </Text>
            {layout === "small" ? (
              <Dashes borderColor={textColor || theme.colors.primary} />
            ) : null}
          </TouchView>
        )}
      </View>
    </View>
  );
});
