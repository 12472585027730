import { AngleType, Segment } from "./GeoJsonSegmenter";
import { AdjusterConstructor } from "./GeoJsonEnhancer";
import { PowerVariables } from "./PowerVariables";
import { limitAbility } from "./limitAbility";

export class BikeAdjuster {
  private data: AdjusterConstructor;

  constructor(data: AdjusterConstructor) {
    this.data = data;
  }

  getSpeedAdjustment(segment: Segment): number {
    return this.getBikeSpeedAdjustment(segment);
  }

  private getBikeSpeedAdjustment(segment: Segment): number {
    const basePower = this.getPower(
      this.data.weight,
      this.data.baseSpeed / 1000 / 3.6,
      0,
    );

    let speed = this.estimateSpeed(
      basePower,
      this.data.weight,
      segment.percentAngle,
    );
    if (segment.percentAngle >= AngleType.LOW) {
      speed *= limitAbility(this.data.climberAbility);
    }
    if (segment.percentAngle <= AngleType.LOW) {
      speed *= limitAbility(this.data.descenderAbility);
    }
    return speed;
  }

  private estimateSpeed(
    powerWatts: number,
    totalWeightKg: number,
    percentAngle: number,
  ): number {
    let minSpeedMs = 0;
    let maxSpeedMs = 24;
    let speedMs = (maxSpeedMs + minSpeedMs) / 2;

    const speedPrecision = 0.01;
    while (maxSpeedMs - minSpeedMs > speedPrecision) {
      speedMs = (maxSpeedMs + minSpeedMs) / 2;
      const P = this.getPower(totalWeightKg, speedMs, percentAngle);

      if (P > powerWatts) {
        maxSpeedMs = speedMs;
      } else {
        minSpeedMs = speedMs;
      }
    }

    return speedMs * 3.6 * 1000;
  }

  private getPower(
    totalWeightKg: number,
    speedMs: number,
    percentAngle: number,
  ): number {
    const { Crr, Cd, A, rho, eta } = PowerVariables;

    const g = 9.81; // Acceleration due to gravity in m/s^2
    const Frr = Crr * totalWeightKg * g; // Rolling resistance force
    const Faero = 0.5 * Cd * A * rho * speedMs * speedMs; // Aerodynamic force
    const Fslope = totalWeightKg * g * Math.sin(Math.atan(percentAngle / 100));

    const P = ((Frr + Faero + Fslope) * speedMs) / eta;

    return P;
  }
}
