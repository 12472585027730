import React, { useMemo } from "react";
import { TimeConverter } from "../../../domain/race-calculator/TimeConverter";
import { computeSpeed } from "../../../domain/race-calculator/compute-speed";
import { Time } from "../../../domain/race-calculator/Time";
import { Distance } from "../../../domain/race-calculator/Distance";
import { Sport } from "../../../models/sport/sport";
import { observer } from "mobx-react-lite";
import { updateSpeed } from "./utils/updateSpeed";
import { TimeEdition } from "./TimeEdition";

interface TimeDisplayParams {
  distance: Distance;
  textColor?: string | null;
  sport: Sport;
  layout?: "big" | "small";
  onEdition?: (inEdition: boolean) => void;
}

export const SportTimeEdition = observer(function SportTimeEdition(
  props: TimeDisplayParams,
) {
  const { distance, sport, layout, onEdition } = props;
  const speed = sport.domainSpeed.getInAdaptedDistanceSize();

  const time = useMemo(
    () => TimeConverter.toDomain(distance.getInSmallSize(), speed),
    [distance, speed],
  );

  function handleTime(t: Time) {
    updateSpeed(
      sport,
      computeSpeed(TimeConverter.toSeconds(t), distance.getInSmallSize()),
    );
  }

  return (
    <TimeEdition
      time={time}
      onTimeChange={handleTime}
      layout={layout}
      onInEdition={onEdition}
    />
  );
});
