export function limitAbility(ability: number): number {
  const originalMin = 0.5;
  const originalMax = 1.5;
  const newMin = 0.75;
  const newMax = 1.25;
  const scaledAbility =
    newMin +
    ((ability - originalMin) * (newMax - newMin)) / (originalMax - originalMin);
  return Math.max(newMin, Math.min(newMax, scaledAbility));
}
