import { EnhancedSegment } from "../../services/analysis/GeoJsonEnhancer";

export const defaultSegment: EnhancedSegment = {
  altitude: 0,
  cumulativeTime: 0,
  length: 0,
  distance: 0,
  speed: 0,
  cumulativeElevationLoss: 0,
  cumulativeElevationGain: 0,
  time: 0,
  elevationLoss: 0,
  elevationGain: 0,
  lapTimes: [],
  percentAngle: 0,
  coordinates: [],
  waypoints: [],
};
