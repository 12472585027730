import React from "react";
import { observer } from "mobx-react-lite";
import { spacing } from "../../theme";
import { Sport } from "../../models/sport/sport";
import { View } from "react-native";
import { MASEdition } from "./converters/MASEdition";
import { CardToggle } from "../CardToggle";
import { translate } from "swunitch-i18n";
import { CENTER_MIDDLE } from "../../theme/view-style";
import { MASPercentComponent } from "./MASPercentComponent";
import { Distance } from "../../domain/race-calculator/Distance";

export const MASSelector = observer(function MASSelector(props: {
  sport: Sport;
  distance?: Distance;
}) {
  const { sport, distance } = props;

  return (
    <View style={{ gap: spacing.small }}>
      <View style={[CENTER_MIDDLE, { gap: spacing.small }]}>
        <View style={{ flex: 1 }}>
          <CardToggle
            title={translate("common.yes")}
            value={sport.useAerobicCapacity}
            onToggle={() => sport.updateUseAerobicCapacity(true)}
          />
        </View>
        <View style={{ flex: 1 }}>
          <CardToggle
            title={translate("common.no")}
            value={!sport.useAerobicCapacity}
            onToggle={() => sport.updateUseAerobicCapacity(false)}
          />
        </View>
      </View>

      {sport.useAerobicCapacity && <MASEdition sport={sport} />}

      {sport.useAerobicCapacity && (
        <MASPercentComponent sport={sport} distance={distance} />
      )}
    </View>
  );
});
