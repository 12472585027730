import { applySnapshot, Instance, SnapshotOut, types } from "mobx-state-tree";
import { Race, RaceModel } from "../race/race";
import { api } from "../../services/api";
import { Sport, SportModel } from "../sport/sport";
import { DistanceSize } from "../../domain/race-calculator/DistanceSize";
import { DistanceModel } from "../distance/distance";
import { v4 as uuid } from "uuid";
import { recordEvent } from "../../services/plausible";
import { SearchModel } from "../search";
import { alertMessage } from "../../services/AlertMessage";
import { defaultRace } from "../../screens/default-race";
import { cloneDeep } from "lodash";
import { Category } from "../../domain/category";
import { RaceType } from "../race/race-type";
import { Platform } from "react-native";

const DEFAULT_RUN_DISTANCES = [
  { id: uuid(), value: 0, distanceSize: DistanceSize.long },
  { id: uuid(), value: 100, distanceSize: DistanceSize.short },
  { id: uuid(), value: 2.5, distanceSize: DistanceSize.long },
  { id: uuid(), value: 5, distanceSize: DistanceSize.long },
  { id: uuid(), value: 10, distanceSize: DistanceSize.long },
  { id: uuid(), value: 20, distanceSize: DistanceSize.long },
  { id: uuid(), value: 21.1, distanceSize: DistanceSize.long },
  { id: uuid(), value: 30, distanceSize: DistanceSize.long },
  { id: uuid(), value: 40, distanceSize: DistanceSize.long },
  { id: uuid(), value: 42.195, distanceSize: DistanceSize.long },
  { id: uuid(), value: 45, distanceSize: DistanceSize.long },
  { id: uuid(), value: 50, distanceSize: DistanceSize.long },
  { id: uuid(), value: 80, distanceSize: DistanceSize.long },
];

const DEFAULT_BIKE_DISTANCES = [
  { id: uuid(), value: 0, distanceSize: DistanceSize.long },
  { id: uuid(), value: 10, distanceSize: DistanceSize.long },
  { id: uuid(), value: 20, distanceSize: DistanceSize.long },
  { id: uuid(), value: 30, distanceSize: DistanceSize.long },
  { id: uuid(), value: 40, distanceSize: DistanceSize.long },
  { id: uuid(), value: 80, distanceSize: DistanceSize.long },
  { id: uuid(), value: 90, distanceSize: DistanceSize.long },
  { id: uuid(), value: 120, distanceSize: DistanceSize.long },
  { id: uuid(), value: 180, distanceSize: DistanceSize.long },
];

const DEFAULT_SWIM_DISTANCES = [
  { id: uuid(), value: 0, distanceSize: DistanceSize.short },
  { id: uuid(), value: 400, distanceSize: DistanceSize.short },
  { id: uuid(), value: 750, distanceSize: DistanceSize.short },
  { id: uuid(), value: 1000, distanceSize: DistanceSize.short },
  { id: uuid(), value: 1500, distanceSize: DistanceSize.short },
  { id: uuid(), value: 1900, distanceSize: DistanceSize.short },
  { id: uuid(), value: 2000, distanceSize: DistanceSize.short },
  { id: uuid(), value: 3000, distanceSize: DistanceSize.short },
  { id: uuid(), value: 3800, distanceSize: DistanceSize.short },
  { id: uuid(), value: 4000, distanceSize: DistanceSize.short },
];

export const GlobalStoreModel = types
  .model("GlobalStore")
  .props({
    migrationVersion: types.optional(types.number, 8), // NOTE: should be one more than the last migration
    numberOfOpenings: types.optional(types.number, 0),
    localRaces: types.optional(types.array(RaceModel), []),
    races: types.optional(types.array(RaceModel), []),
    favoritedRaces: types.optional(types.array(RaceModel), []),
    favoriteRaces: types.optional(types.array(types.string), []),
    hasRunTour: types.optional(types.boolean, false),
    searchedRaces: types.optional(types.array(RaceModel), []),
    nearbyRaces: types.optional(types.array(RaceModel), []),
    isMetricSystem: types.optional(types.boolean, true),
    latitude: types.maybe(types.number),
    longitude: types.maybe(types.number),
    search: types.optional(SearchModel, {}),

    sport: types.optional(
      types.model({
        [RaceType.Run]: SportModel,
        [RaceType.Bike]: SportModel,
        [RaceType.Swim]: SportModel,
      }),
      {
        run: {
          type: RaceType.Run,
          speed: 11500,
          effort: 100,
          aerobicCapacity: 15000,
          distanceSize: DistanceSize.long,
          distances: DEFAULT_RUN_DISTANCES,
          distance: DEFAULT_RUN_DISTANCES[3],
        },
        bike: {
          type: RaceType.Bike,
          speed: 31500,
          effort: 100,
          aerobicCapacity: 120,
          distanceSize: DistanceSize.long,
          distances: DEFAULT_BIKE_DISTANCES,
          distance: DEFAULT_BIKE_DISTANCES[3],
        },
        swim: {
          type: RaceType.Swim,
          speed: 3790,
          effort: 100,
          aerobicCapacity: 4500,
          distanceSize: DistanceSize.short,
          distances: DEFAULT_SWIM_DISTANCES,
          distance: DEFAULT_SWIM_DISTANCES[3],
        },
      },
    ),
  })
  .views((self) => ({
    get isEnoughOpeningsForReview() {
      return self.numberOfOpenings > 7;
    },
    getSportByRaceType(type: RaceType): Sport {
      return self.sport[type];
    },
    isFavoriteRace(raceId: string): boolean {
      return self.favoriteRaces.includes(raceId);
    },
  }))
  .actions((self) => ({
    runMigrations: function () {
      [1, 2, 3, 4, 5, 6, 7].forEach((version) => {
        if (self.migrationVersion === 7) {
          self.sport.run.isTimeObjective = true;
          self.sport.bike.isTimeObjective = true;
          self.sport.swim.isTimeObjective = true;
          self.sport.run.aerobicCapacityPercentage = 80;
          self.sport.bike.aerobicCapacityPercentage = 80;
          self.sport.swim.aerobicCapacityPercentage = 80;
        }

        if (self.migrationVersion === 6) {
          // NOTE: mas field was removed
          // self.sport.run.mas = 15000;
        }

        if (self.migrationVersion === 5) {
          self.sport.run.type = RaceType.Run;
          self.sport.bike.type = RaceType.Bike;
          self.sport.swim.type = RaceType.Swim;
        }

        if (self.migrationVersion === 4) {
          self.sport.run.distance = DistanceModel.create({
            ...DEFAULT_RUN_DISTANCES[3],
            id: uuid(),
          });
          self.sport.bike.distance = DistanceModel.create({
            ...DEFAULT_BIKE_DISTANCES[3],
            id: uuid(),
          });
          self.sport.swim.distance = DistanceModel.create({
            ...DEFAULT_SWIM_DISTANCES[3],
            id: uuid(),
          });
        }

        if (self.migrationVersion === 3) {
          self.sport.run.distances.replace(
            DEFAULT_RUN_DISTANCES.map((d) =>
              DistanceModel.create({
                id: uuid(),
                value: d.value,
                distanceSize: d.distanceSize,
              }),
            ),
          );
          self.sport.bike.distances.replace(
            DEFAULT_BIKE_DISTANCES.map((d) =>
              DistanceModel.create({
                id: uuid(),
                value: d.value,
                distanceSize: d.distanceSize,
              }),
            ),
          );
          self.sport.swim.distances.replace(
            DEFAULT_SWIM_DISTANCES.map((d) =>
              DistanceModel.create({
                id: uuid(),
                value: d.value,
                distanceSize: d.distanceSize,
              }),
            ),
          );
        }

        if (self.migrationVersion <= version) {
          self.migrationVersion = version + 1;
        }
      });
    },
    clear: function () {
      applySnapshot(self, {});
    },
    clearNumberOfOpenings: function () {
      self.numberOfOpenings = 0;
    },
    shouldRunTour: function (): boolean {
      if (Platform.OS === "web") {
        return false;
      }
      if (self.hasRunTour) {
        return false;
      } else {
        self.hasRunTour = true;
        return true;
      }
    },
    hasOpenedScreen() {
      self.numberOfOpenings = self.numberOfOpenings + 1;
    },
    createLocalRace: function (type: RaceType): Race {
      const race = RaceModel.create({
        ...cloneDeep(defaultRace),
        id: uuid(),
        type,
        isLocal: true,
      });

      self.localRaces.push(race);
      return race;
    },
    removeLocalRace: function (race: Race) {
      const index = self.localRaces.findIndex((r) => r.id === race.id);
      if (index !== -1) {
        self.localRaces.splice(index, 1);
      }
    },
    getCategories: async function (): Promise<Array<Category>> {
      const response = await api.getCategories();
      return response.kind === "ok" ? response.categories : [];
    },
    fetchRaces: async function (): Promise<void> {
      const response = await api.getRaces();
      this.fetchFavorites();

      if (response.kind === "ok") {
        this._setRaces(response.races);
      }
    },
    fetchSearch: async function (): Promise<void> {
      const response = await api.getRaces(self.search.searchDomain);

      if (response.kind === "ok") {
        this._setSearchedRaces(response.races);
      }
    },
    fetchFavorites: async function (): Promise<void> {
      if (self.favoriteRaces.length === 0) {
        this._setFavoriteRaces([]);
      } else {
        const response = await api.getRaces({
          ids: self.favoriteRaces.join(",") as unknown as Array<string>,
        });

        if (response.kind === "ok") {
          this._setFavoriteRaces(response.races);
          this.fetchFavoriteGeoJson();
        }
      }
    },
    fetchFavoriteGeoJson: async function (): Promise<void> {
      for (const r of self.favoritedRaces) {
        if (self.favoriteRaces.includes(r.id)) {
          r.fetchGeoJson();
        }
      }
    },
    fetchNearbyRaces: async function (): Promise<void> {
      if (self.latitude && self.longitude) {
        const response = await api.getRaces({
          latitude: self.latitude,
          longitude: self.longitude,
        });

        if (response.kind === "ok") {
          this._setNearbyRaces(response.races);
        }
      }
    },
    switchSystem(): void {
      self.sport.run.updateSpeed(
        self.sport.run.domainSpeed
          .changeSystemForLongDistance(!self.isMetricSystem)
          .getValue(),
      );

      self.sport.bike.updateSpeed(
        self.sport.bike.domainSpeed
          .changeSystemForLongDistance(!self.isMetricSystem)
          .getValue(),
      );

      self.sport.swim.updateSpeed(
        self.sport.swim.domainSpeed
          .changeSystemForShortDistance(!self.isMetricSystem)
          .getValue(),
      );

      self.isMetricSystem = !self.isMetricSystem;
    },
    async toggleFavoriteRace(raceId: string): Promise<void> {
      const index = self.favoriteRaces.indexOf(raceId);

      if (index === -1) {
        self.favoriteRaces.push(raceId);

        recordEvent(`races/${raceId}`, "fav");
      } else {
        await alertMessage(
          "runScreen.removeFavoriteTitle",
          "runScreen.removeFavoriteDescription",
        );
        this._removeFavoriteRace(index);
      }

      this.fetchFavorites();
    },
    setNearMeLocation(latitude: number, longitude: number): void {
      self.latitude = latitude;
      self.longitude = longitude;
    },
    _removeFavoriteRace(index: number): void {
      self.favoriteRaces.splice(index, 1);
    },
    _setRaces(races) {
      self.races = races;
    },
    _setSearchedRaces(races) {
      self.searchedRaces = races;
    },
    _setFavoriteRaces(races) {
      self.favoritedRaces = races;
    },
    _setNearbyRaces(races) {
      self.nearbyRaces = races;
    },
  }));

type GlobalStoreType = Instance<typeof GlobalStoreModel>;

export interface GlobalStore extends GlobalStoreType {}

type GlobalStoreSnapshotType = SnapshotOut<typeof GlobalStoreModel>;

export interface GlobalStoreSnapshot extends GlobalStoreSnapshotType {}

export const createGlobalStoreDefaultModel = () =>
  types.optional(GlobalStoreModel, {});
