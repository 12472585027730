import React, { FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { AppStackScreenProps } from "../navigators";
import { Button, Screen, Text, TextField } from "../components";
import * as DocumentPicker from "expo-document-picker";
import { GPXParser } from "../services/analysis/GPXParser";
import * as FileSystem from "expo-file-system";
import { CONTAINER } from "../theme/view-style";
import { View } from "react-native";
import { useOrientation } from "../utils/orientation-style";
import { spacing } from "../theme";
import { translate } from "swunitch-i18n";
import { GeoJsonMetadata } from "../services/analysis/GeoJsonMetadata";
import { formatNumber } from "../utils/formatNumber";
import { useStores } from "../models";
import { useBrandTheme } from "../theme/use-brand-theme";
import { SportSelector } from "../components/SportSelector";
import { RaceType } from "../models/race/race-type";

export const EditRaceScreen: FC<AppStackScreenProps<"edit-race">> = observer(
  function EditRaceScreen({ route, navigation }) {
    const { race } = route.params;
    const { globalStore } = useStores();
    const deviceOrientation = useOrientation();
    const [gpxFile, setGpxFile] = useState<string | null>(null);
    const theme = useBrandTheme();

    const handleGpxFilePick = async () => {
      const result = await DocumentPicker.getDocumentAsync({
        type: "application/octet-stream",
        multiple: false,
      });
      if (!result.canceled && result.assets.length) {
        const fileContent = await FileSystem.readAsStringAsync(
          result.assets[0].uri,
        );
        setGpxFile(result.assets[0].name);

        const geoJson = GPXParser.convertGPXToGeoJSON(fileContent);
        const metadata = new GeoJsonMetadata(geoJson).get();
        race.updateLocal({
          geoJson: JSON.stringify(geoJson),
          distance: Number(formatNumber(metadata.totalLength || 0)),
        });
      }
    };

    const validateFields = () => {
      if (!race.title || race.title.trim() === "") {
        return false;
      }

      if (!race.geoJson) {
        return false;
      }

      return true;
    };

    useEffect(
      () =>
        navigation.addListener("beforeRemove", () => {
          if (!validateFields()) {
            globalStore.removeLocalRace(race);
          }
        }),
      [navigation],
    );

    return (
      <Screen preset="scroll">
        <View style={CONTAINER(deviceOrientation)}>
          <TextField
            containerStyle={{ marginTop: spacing.medium }}
            value={race.title}
            placeholderTx="editRaceScreen.placeholder"
            onChangeText={(t) => race.updateLocal({ title: t })}
            label={`${translate("editRaceScreen.name")} (${translate(("sports." + race.type) as "sports.run")})`}
          />
          <SportSelector
            type={race.type as RaceType}
            onChange={(t) => {
              race.updateLocal({ type: t });
            }}
          />
          <Button
            style={{ marginTop: spacing.medium }}
            preset="outlined"
            tx="raceScreen.gpxFile"
            onPress={handleGpxFilePick}
          />
          <Text>{gpxFile}</Text>

          <Button
            preset={validateFields() ? "reversed" : "light"}
            tx="common.ok"
            style={
              validateFields() ? { backgroundColor: theme.colors.primary } : {}
            }
            disabled={!validateFields()}
            onPress={() => {
              navigation.goBack();
            }}
          />
        </View>
      </Screen>
    );
  },
);
