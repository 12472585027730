import { RaceType } from "../models/race/race-type";
import { formatNumber } from "./formatNumber";
import { KilometerPerHour } from "../domain/speed-converters/KilometerPerHour";
import { MinPerKilometer } from "../domain/speed-converters/MinPerKilometer";

export function getSpeedForRaceType(speed: number, type: RaceType): string {
  if (type === RaceType.Bike) {
    return formatNumber(KilometerPerHour.fromMeterPerHour(speed));
  }

  return MinPerKilometer.fromMeterPerHour(speed);
}
