import { AppDarkTheme, palette, spacing } from "../../theme";
import React from "react";
import { View } from "react-native";
import { ImageBackground } from "expo-image";
import { Text } from "../../components/Text";
import { Card } from "../../components/Card";
import {
  CENTER_MIDDLE,
  CONTAINER,
  RACE_COLUMN,
  RACE_LINE_CONTAINER,
  RACE_OPTIONS,
} from "../../theme/view-style";
import { observer } from "mobx-react-lite";
import { translate } from "swunitch-i18n";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { useOrientation } from "../../utils/orientation-style";
import { RaceType } from "../../models/race/race-type";
import { imageRegistry } from "../../components";

interface AddCourseProps {
  onPress: () => void;
  type: RaceType;
}

export const AddCourse = observer(function AddCourse(props: AddCourseProps) {
  const { type, onPress } = props;
  const theme = useBrandTheme();
  const deviceOrientation = useOrientation();

  return (
    <Card
      onPress={onPress}
      style={{
        backgroundColor: palette.neutral900,
        marginTop: 0,
      }}
    >
      <ImageBackground
        source={imageRegistry.blueBackground}
        style={[
          {
            justifyContent: "center",
            alignItems: "center",
          },
          RACE_LINE_CONTAINER,
        ]}
      >
        <View style={[CENTER_MIDDLE, CONTAINER(deviceOrientation)]}>
          <View style={RACE_COLUMN}>
            <Text
              preset="bold"
              style={{
                color: AppDarkTheme.colors.text,
              }}
            >
              {translate("editRaceScreen.add")} (
              {translate(("sports." + type) as "sports.run")})
            </Text>
          </View>
          <View style={RACE_OPTIONS}>
            <MaterialCommunityIcons
              name="plus-circle"
              size={20}
              color={theme.colors.primary}
              onPress={onPress}
              style={{ padding: spacing.extraSmall, paddingRight: 0 }}
            />
          </View>
        </View>
      </ImageBackground>
    </Card>
  );
});
