import { View } from "react-native";
import React, { useMemo } from "react";
import {
  BOTTOM_SHEET_TEXT,
  BOTTOM_SHEET_UNIT_TEXT,
  INPUT_SHADOW,
  UNIT_DISPOSITION,
} from "../../../theme/view-style";
import { translate } from "swunitch-i18n";
import { ConverterProps } from "./utils/ConverterProps";
import { MinPerKilometer } from "../../../domain/speed-converters/MinPerKilometer";
import { Text } from "../../Text";
import { useStores } from "../../../models";
import { getPaceUnit } from "../../../utils/humanUnit";
import { updateSpeed } from "./utils/updateSpeed";
import { observer } from "mobx-react-lite";
import { EditableConverter } from "./utils/EditableConverter";

export const MinPerKilometerComponent = observer(
  function MinPerKilometerComponent(props: ConverterProps) {
    const { sport, disabled } = props;
    const { globalStore } = useStores();

    const pace = useMemo(
      () =>
        MinPerKilometer.fromMeterPerHour(
          sport.domainSpeed.getSpeedWithEffort(),
        ),
      [sport.speed, sport.effort],
    );

    function handlePace(p: string) {
      updateSpeed(sport, MinPerKilometer.toMeterPerHour(p));
    }

    return (
      <View>
        <Text preset="subheading" style={BOTTOM_SHEET_TEXT}>
          {translate("units.pace")}
        </Text>

        <View style={UNIT_DISPOSITION}>
          <EditableConverter
            mask={(value) => {
              if (value && value.length > 4) {
                return [/\d/, /\d/, ":", /\d/, /\d/];
              }

              return [/\d/, ":", /\d/, /\d/];
            }}
            onValueChange={handlePace}
            value={pace}
            testID="pace"
            disabled={disabled}
          />

          <View style={INPUT_SHADOW}>
            <Text
              style={BOTTOM_SHEET_UNIT_TEXT}
              onPress={() => globalStore.switchSystem()}
            >
              {getPaceUnit(globalStore.isMetricSystem)}
            </Text>
          </View>
        </View>
      </View>
    );
  },
);
