import React from "react";
import { View } from "react-native";
import { Text } from "../Text";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { spacing } from "../../theme";
import { Card } from "../Card";
import { DimensionValue } from "react-native/Libraries/StyleSheet/StyleSheetTypes";

interface StatisticsProps {
  width: DimensionValue;
  statistics: {
    value: string;
    icon: React.ReactNode;
    iconText: string;
  }[];
}

export function Statistics({ statistics, width }: StatisticsProps) {
  const theme = useBrandTheme();

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-around",
        flexWrap: "wrap",
        borderColor: theme.colors.border,
        marginVertical: spacing.tiny,
        gap: spacing.tiny,
      }}
    >
      {statistics.map((stat, index) => (
        <Card
          key={index}
          style={{
            width,
          }}
          paddingHorizontal={spacing.extraSmall}
          paddingVertical={spacing.extraSmall}
          noShadow
          withBorder
        >
          <View>
            <View>
              <Text
                size="xs"
                preset="formHelper"
                style={{ color: theme.colors.textDim }}
              >
                {stat.icon} {stat.iconText}
              </Text>
            </View>

            <View>
              <Text preset="formLabel">{stat.value}</Text>
            </View>
          </View>
        </Card>
      ))}
    </View>
  );
}
