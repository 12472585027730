/**
 * Welcome to the main entry point of the app. In this file, we'll
 * be kicking off our app.
 *
 * Most of this file is boilerplate and you shouldn't need to modify
 * it very often. But take some time to look through and understand
 * what is going on here.
 *
 * The app navigation resides in ./app/navigators, so head over there
 * if you're interested in adding screens and navigators.
 */
import "./i18n/i18n";
import "./utils/ignoreWarnings";
import "react-native-url-polyfill/auto";
import { useFonts } from "expo-font";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as Device from "expo-device";
import { DeviceType } from "expo-device";
import {
  initialWindowMetrics,
  SafeAreaProvider,
} from "react-native-safe-area-context";
import "react-native-get-random-values";
import { useInitialRootStore } from "./models";
import { AppNavigator, useNavigationPersistence } from "./navigators";
import { ErrorBoundary } from "./screens/ErrorScreen/ErrorBoundary";
import * as storage from "./utils/storage";
import { customFontsToLoad } from "./theme";
import Config from "./config";
import { Platform } from "expo-modules-core";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { ImageBackground } from "expo-image";
import {
  Animated,
  Image,
  StyleSheet,
  useWindowDimensions,
  View,
} from "react-native";

// FIXME need reanimated update, see https://github.com/software-mansion/react-native-reanimated/issues/3355
if (Platform.OS === "web") {
  // @ts-ignore
  window._frameTimestamp = null;
}

export const NAVIGATION_PERSISTENCE_KEY = "NAVIGATION_STATE";

interface AppProps {
  hideSplashScreen: () => Promise<void>;
}

/**
 * This is the root component of our app.
 */
function App(props: AppProps) {
  const { hideSplashScreen } = props;
  const {
    initialNavigationState,
    onNavigationStateChange,
    isRestored: isNavigationStateRestored,
  } = useNavigationPersistence(storage, NAVIGATION_PERSISTENCE_KEY);

  const [areFontsLoaded] = useFonts(customFontsToLoad);

  const { rehydrated } = useInitialRootStore();

  // Before we show the app, we have to wait for our state to be ready.
  // In the meantime, don't render anything. This will be the background
  // color set in native by rootView's background color.
  // In iOS: application:didFinishLaunchingWithOptions:
  // In Android: https://stackoverflow.com/a/45838109/204044
  // You can replace with your own loading component if you wish.
  if (!rehydrated || !isNavigationStateRestored || !areFontsLoaded) return null;

  // otherwise, we're ready to render the app
  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <SafeAreaProvider initialMetrics={initialWindowMetrics}>
        <ErrorBoundary catchErrors={Config.catchErrors}>
          <AnimatedSplashScreen hideSplashScreen={hideSplashScreen}>
            <AppNavigator
              initialState={initialNavigationState}
              onStateChange={onNavigationStateChange}
            />
          </AnimatedSplashScreen>
        </ErrorBoundary>
      </SafeAreaProvider>
    </GestureHandlerRootView>
  );
}

function AnimatedSplashScreen({ children, hideSplashScreen }) {
  const animation = useMemo(() => new Animated.Value(1), []);
  const [isAppReady, setAppReady] = useState(Platform.OS === "web");
  const [isSplashVideoComplete, setSplashVideoComplete] = useState(
    Platform.OS === "web",
  );
  const [isSplashAnimationComplete, setAnimationComplete] = useState(
    Platform.OS === "web",
  );

  useEffect(() => {
    if (isAppReady && isSplashVideoComplete) {
      Animated.timing(animation, {
        toValue: 0,
        duration: 200,
        useNativeDriver: true,
      }).start(() => setAnimationComplete(true));
    }
  }, [isAppReady, isSplashVideoComplete]);

  const onImageLoaded = useCallback(async () => {
    // If your initialization scripts run very fast, it's good to show the splash screen for just a bit longer to prevent flicker.
    // Slightly delaying splash screen hiding for better UX; can be customized or removed as needed,
    setTimeout(async () => {
      try {
        await hideSplashScreen();
        // Load stuff
        await Promise.all([]);
      } catch (e) {
        // handle errors
      } finally {
        setAppReady(true);
      }
    }, 300);
  }, []);

  const videoElement = useMemo(() => {
    return (
      <SplashVideo
        onLoaded={onImageLoaded}
        onFinish={() => {
          setSplashVideoComplete(true);
        }}
      />
    );
  }, [onImageLoaded, setSplashVideoComplete]);

  return (
    <View style={{ flex: 1 }}>
      {isAppReady && children}
      {!isSplashAnimationComplete && (
        <Animated.View
          pointerEvents="none"
          style={[
            StyleSheet.absoluteFill,
            {
              opacity: animation,
            },
          ]}
        >
          {videoElement}
        </Animated.View>
      )}
    </View>
  );
}

const splash = {
  web: require("../assets/images/splash-logo-web.png"),
  iosMobile: require("../assets/images/splash-logo-ios-mobile.png"),
  iosTablet: require("../assets/images/splash-logo-ios-tablet.png"),
  android: require("../assets/images/splash-logo-android-universal.png"),
  gif: require("../assets/images/logo-light-pacevisor-2.png"),
};

export function SplashVideo({ onLoaded, onFinish }) {
  const isTablet = Device.deviceType === DeviceType.TABLET;
  const layout = useWindowDimensions();

  const splashImage =
    Platform.OS === "ios"
      ? isTablet
        ? splash.iosTablet
        : splash.iosMobile
      : Platform.OS === "android"
        ? splash.android
        : splash.web;

  useEffect(() => {
    onLoaded();

    setTimeout(() => {
      onFinish();
    }, 100);
  }, []);

  return (
    <ImageBackground style={StyleSheet.absoluteFill} source={splashImage}>
      <Image
        source={splash.gif}
        style={{
          width: layout.width / 1.3,
          height: layout.height,
          resizeMode: "contain",
          alignSelf: "center",
        }}
      />
    </ImageBackground>
  );
}

export default App;
