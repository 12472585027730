import { TextStyle, View, ViewStyle } from "react-native";
import { BIG_INPUT, BIG_INPUT_TEXT } from "../../../theme/view-style";
import { useBrandTheme } from "../../../theme/use-brand-theme";
import React from "react";
import { Text } from "../../Text";

export function SimpleDisplayBigValue(props: {
  title?: string;
  value: string;
  style?: ViewStyle;
  textStyle?: TextStyle;
}) {
  const { textStyle, style, value, title } = props;
  const theme = useBrandTheme();

  return (
    <View>
      {title ? <Text preset="subheading">{title}</Text> : null}
      <View style={[BIG_INPUT(theme), style]}>
        <Text style={[BIG_INPUT_TEXT, textStyle]}>{value}</Text>
      </View>
    </View>
  );
}
