import React, { useEffect, useState } from "react";
import { View } from "react-native";
import * as ExpoLocation from "expo-location";
import * as turf from "@turf/turf";
import { EnhancedSegment } from "../../services/analysis/GeoJsonEnhancer";
import { observer } from "mobx-react-lite";
import { FeatureCollection, Geometry } from "@turf/helpers";
import { RunnerLocation, RunnerTracker } from "./RunnerTracker";
import * as Linking from "expo-linking";
import { Button } from "../Button";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { spacing } from "../../theme";
import { Text } from "../Text";
import { formatNumber } from "../../utils/formatNumber";
import { KilometerPerHour } from "../../domain/speed-converters/KilometerPerHour";
import { SimpleDisplayBigValue } from "../sports/converters/SimpleDisplayBigValue";
import { CENTER_MIDDLE } from "../../theme/view-style";
import { translate } from "swunitch-i18n";
import { TimeConverter } from "../../domain/race-calculator/TimeConverter";
import { RaceType } from "../../models/race/race-type";
import { getSpeedForRaceType } from "../../utils/getSpeedForRaceType";
import { Metrics } from "../race-analysis/graphs/Metrics";
import {
  normalizeSpeedFromGeolocation,
  startBackgroundTracking,
  stopBackgroundTracking,
} from "../GeolocationWrapper";

interface GhostProps {
  geoJson: FeatureCollection<Geometry>;
  enhancedSegments: EnhancedSegment[];
  onPositionChange: (position: RunnerLocation) => void;
  type: RaceType;
}

export interface PositionHistory {
  coords: turf.helpers.Position;
  timestamp: number;
  speed: number;
}

export const Ghost = observer(function Ghost(props: GhostProps) {
  const { geoJson, enhancedSegments, onPositionChange, type } = props;
  const theme = useBrandTheme();
  const [tracker, setTracker] = useState(
    new RunnerTracker(geoJson, enhancedSegments),
  );
  const [advice, setAdvice] = useState<string>("");
  const [isRunning, setIsRunning] = useState(false);
  const [runnerLocation, setRunnerLocation] = useState<RunnerLocation | null>(
    null,
  );
  const [speed, setSpeed] = useState<number>(0);
  const [averageSpeed, setAverageSpeed] = useState<number>(0);
  const [positionHistory, setPositionHistory] = useState<PositionHistory[]>([]);

  useEffect(() => {
    setTracker(new RunnerTracker(geoJson, enhancedSegments));
  }, [geoJson, enhancedSegments]);

  useEffect(() => {
    if (!isRunning) {
      return;
    }

    const startLocationTracking = async () => {
      const foregroundPermission =
        await ExpoLocation.requestForegroundPermissionsAsync();
      if (!foregroundPermission.granted) {
        setIsRunning(false);
        await Linking.openSettings();
        return;
      }
      const backgroundPermission = await startBackgroundTracking(
        geoJson,
        enhancedSegments,
        type,
      );
      if (!backgroundPermission.granted) {
        setIsRunning(false);
        await Linking.openSettings();
        return;
      }

      return ExpoLocation.watchPositionAsync(
        {
          accuracy: ExpoLocation.Accuracy.High,
          distanceInterval: 5,
        },
        (location) => {
          if (isRunning) {
            handleNewPosition(location);
          }
        },
      );
    };

    const promise = startLocationTracking();

    return () => {
      promise.then(async (client) => {
        if (client) {
          client.remove();
        }
        await stopBackgroundTracking();
      });
    };
  }, [isRunning]);

  const handleNewPosition = (location: ExpoLocation.LocationObject) => {
    const currentPosition: turf.helpers.Position = [
      location.coords.longitude,
      location.coords.latitude,
    ];
    const speedFromGeolocation =
      location.coords.speed === null
        ? 0
        : normalizeSpeedFromGeolocation(location.coords.speed * 3600);
    setSpeed(speedFromGeolocation);
    setPositionHistory((prev) =>
      [
        ...prev,
        {
          coords: currentPosition,
          timestamp: location.timestamp,
          speed: speedFromGeolocation,
        },
      ].slice(-20),
    );

    const r = tracker.locateRunner(
      currentPosition,
      runnerLocation?.distanceFromStart || 0,
    );
    onPositionChange(r);
    setRunnerLocation(r);
  };

  useEffect(() => {
    // NOTE: change only position history (and tracker if it changes from segment)
    if (positionHistory.length < 2) {
      setAverageSpeed(0);
      return;
    }

    const runnerSpeed = tracker.computeSpeedFromHistory(positionHistory);
    if (runnerSpeed !== null && runnerLocation !== null) {
      setAverageSpeed(normalizeSpeedFromGeolocation(runnerSpeed));
    }
  }, [positionHistory, tracker]);

  useEffect(() => {
    // NOTE: change only on averageSpeed
    if (runnerLocation !== null && averageSpeed !== 0) {
      giveSpeedAdvice(runnerLocation.segment, averageSpeed);
    }
  }, [averageSpeed]);

  const giveSpeedAdvice = (
    segment: EnhancedSegment,
    currentSpeed: number,
  ): void => {
    const recommendedSpeed = segment.speed;
    const time =
      1000 / (recommendedSpeed / 3600) - 1000 / (currentSpeed / 3600);

    if (time > 1) {
      const t = TimeConverter.toHuman(TimeConverter.fromSecondsToDomain(time));
      setAdvice(
        translate("ghostScreen.tooFast", {
          time: t,
        }),
      );
    } else if (time < -1) {
      const t = TimeConverter.toHuman(TimeConverter.fromSecondsToDomain(-time));
      setAdvice(
        translate("ghostScreen.tooSlow", {
          time: t,
        }),
      );
    } else {
      setAdvice("");
    }
  };

  return (
    <View style={{ gap: spacing.small }}>
      <View />

      {runnerLocation ? (
        <>
          <View style={[CENTER_MIDDLE, { justifyContent: "space-around" }]}>
            <SimpleDisplayBigValue
              title={translate("ghostScreen.yourGhost")}
              value={getSpeedForRaceType(runnerLocation.segment.speed, type)}
              textStyle={{ color: theme.colors.text }}
              style={{ backgroundColor: theme.colors.lightTransparent }}
            />
            <SimpleDisplayBigValue
              title={translate("ghostScreen.yourSelf")}
              value={getSpeedForRaceType(averageSpeed, type)}
              textStyle={{ color: theme.colors.text }}
              style={{ backgroundColor: theme.colors.lightTransparent }}
            />
            <SimpleDisplayBigValue
              title="Live"
              value={getSpeedForRaceType(speed, type)}
              textStyle={{ color: theme.colors.text }}
              style={{ backgroundColor: theme.colors.lightTransparent }}
            />
          </View>

          <View style={{ alignItems: "center" }}>
            <Text preset="subheading" size="xl">
              Km{" "}
              {formatNumber(
                KilometerPerHour.fromMeterPerHour(
                  runnerLocation.segment.distance,
                ),
              )}
            </Text>
            <Metrics
              selectedSegment={runnerLocation.segment}
              type={type}
              actualDistance={runnerLocation.distanceFromStart}
            />
          </View>

          <Text>{advice}</Text>
        </>
      ) : null}

      <Button
        tx={isRunning ? "ghostScreen.stop" : "ghostScreen.start"}
        preset={isRunning ? "filled" : "reversed"}
        style={isRunning ? {} : { backgroundColor: theme.colors.primary }}
        onPress={() => {
          if (isRunning) {
            setIsRunning(false);
          } else {
            setIsRunning(true);
          }
        }}
      />
    </View>
  );
});
