import { AngleType } from "./analysis/GeoJsonSegmenter";
import { palette } from "../theme";

export function getPercentColor(angle: number): string | null {
  if (angle >= AngleType.LOW) {
    return angle >= AngleType.HIGH
      ? palette.high
      : angle >= AngleType.MEDIUM
        ? palette.medium
        : palette.low;
  }
  return null;
}
