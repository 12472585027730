import React, { useCallback, useMemo, useState } from "react";
import { Platform, TouchableOpacity, View, ViewStyle } from "react-native";
import { observer } from "mobx-react-lite";
import { AppDarkTheme, spacing } from "../../../theme";
import { EnhancedSegment } from "../../../services/analysis/GeoJsonEnhancer";
import { Race } from "../../../models/race/race";
import { Speed } from "../../../domain/race-calculator/Speed";
import { useBrandTheme } from "../../../theme/use-brand-theme";
import { LineChart } from "react-native-wagmi-charts";
import * as Haptics from "expo-haptics";
import { translate } from "swunitch-i18n";
import { CENTER_MIDDLE, CONTAINER } from "../../../theme/view-style";
import { defaultSegment } from "../defaultSegment";
import { RaceChart } from "../RaceChart";
import { RaceType } from "../../../models/race/race-type";
import { debounce } from "lodash";
import SegmentedControl from "react-native-segmented-control-2";
import { useOrientation } from "../../../utils/orientation-style";
import { Metrics } from "./Metrics";
import { PacevisorLineChart } from "./PacevisorLineChart";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Text } from "../../Text";
import { ErrorBoundary } from "../../../screens/ErrorScreen/ErrorBoundary";
import { getSpeedForRaceType } from "../../../utils/getSpeedForRaceType";

export interface RaceGraphsProps {
  race: Race;
  profile: EnhancedSegment[];
  segments: EnhancedSegment[];
  selectedSegment: EnhancedSegment;
  onSegmentSelected: (segment: EnhancedSegment) => void;
  isLargeScreen: boolean;
}

export const CHART_CONFIG = {
  graphHeight: 180,
  chartPadding: {
    left: 40,
    bottom: 20,
    right: 20,
    top: 10,
  },
  lineStyle: {
    paddingLeft: 40,
    marginTop: -5,
    position: "absolute",
  } as ViewStyle,
};

export const RaceGraphs = observer(function RaceGraphs(props: RaceGraphsProps) {
  const {
    profile,
    race,
    segments,
    onSegmentSelected,
    selectedSegment,
    isLargeScreen,
  } = props;
  const theme = useBrandTheme();
  const [isFullscreen, setFullscreen] = useState(false);
  const [displayProfile, setDisplayProfile] = useState(true);
  const deviceOrientation = useOrientation();

  const fullscreenWidth = isFullscreen ? 1500 : 0;

  const toggleFullscreen = useCallback(() => {
    setFullscreen(!isFullscreen);
  }, [isFullscreen]);

  const lowestSpeed = useMemo(
    () => Math.max(...profile.map((segment) => segment.speed)),
    [profile],
  );
  const highestSpeed = useMemo(
    () => Math.min(...profile.map((segment) => segment.speed)),
    [profile],
  );

  const onIndexChange = useCallback(
    debounce((index: { index: number; list: "profile" | "segments" }) => {
      const s = getSelectedSegment(index);
      onSegmentSelected(s);
    }, 0),
    [profile, segments],
  );

  const getSelectedSegment = useCallback(
    (index: { index: number; list: "profile" | "segments" }) => {
      return (
        (index.list === "profile"
          ? profile[index.index]
          : segments[index.index]) || defaultSegment
      );
    },
    [profile, segments],
  );

  return (
    <View style={{ marginVertical: spacing.small }}>
      <View style={CONTAINER(deviceOrientation)}>
        <View style={{ ...CENTER_MIDDLE, justifyContent: "space-between" }}>
          <SegmentedControl
            onChange={(index) => {
              setDisplayProfile(index === 0);
            }}
            style={{
              borderRadius: 100,
              width: 300,
            }}
            tabStyle={{
              borderRadius: 100,
              paddingVertical: spacing.extraSmall,
            }}
            selectedTabStyle={{
              borderRadius: 100,
            }}
            tabs={[
              translate("raceScreen.profile"),
              translate("raceScreen.paceTitle"),
            ]}
            activeTabColor={theme.colors.primary}
            activeTextColor={AppDarkTheme.colors.text}
          />

          <TouchableOpacity onPress={toggleFullscreen}>
            <MaterialCommunityIcons
              name={isFullscreen ? "fullscreen-exit" : "fullscreen"}
              size={25}
              color={theme.colors.text}
            />
          </TouchableOpacity>
        </View>

        <Text size="xxs" style={{ color: theme.colors.textDim }}>
          {translate("raceScreen.graphSubtitle")}
        </Text>
      </View>

      {displayProfile && (
        <ErrorBoundary
          catchErrors="always"
          toDisplay={<Text tx="errorScreen.title" />}
        >
          <RaceChart
            data={profile.map((segment) => ({
              x: segment.distance,
              y: segment.altitude,
            }))}
            isLargeScreen={isLargeScreen}
            isFullscreen={isFullscreen}
            fullscreenWidth={fullscreenWidth}
            verticalAxisFormatter={(v) => v.toFixed(1)}
            yDomain={{
              min: Math.min(...profile.map((segment) => segment.altitude)),
              max: Math.max(...profile.map((segment) => segment.altitude)) + 10,
            }}
            tickCount={6}
            lineChart={
              profile.length ? (
                <View style={CHART_CONFIG.lineStyle}>
                  <LineChart.Provider
                    data={profile.map((segment, index) => ({
                      timestamp: index,
                      value: segment.altitude,
                    }))}
                    onCurrentIndexChange={(index) => {
                      if (Platform.OS !== "web") {
                        Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
                      }
                      onIndexChange({
                        index,
                        list: "profile",
                      });
                    }}
                    yRange={{
                      min: Math.min(
                        ...profile.map((segment) => segment.altitude),
                      ),
                      max:
                        Math.max(
                          ...profile.map((segment) => segment.altitude),
                        ) + 10,
                    }}
                  >
                    <PacevisorLineChart
                      graph="profile"
                      fullscreenWidth={fullscreenWidth}
                      {...props}
                    />
                  </LineChart.Provider>
                </View>
              ) : null
            }
          />
        </ErrorBoundary>
      )}

      {!displayProfile && (
        <ErrorBoundary
          catchErrors="always"
          toDisplay={<Text tx="errorScreen.title" />}
        >
          <RaceChart
            data={segments.map((segment) => ({
              x: segment.distance,
              y: segment.speed,
            }))}
            isLargeScreen={isLargeScreen}
            isFullscreen={isFullscreen}
            fullscreenWidth={fullscreenWidth}
            verticalAxisFormatter={(v) =>
              getSpeedForRaceType(
                Speed.of(v).getSpeedWithEffort(),
                race.type as RaceType,
              )
            }
            yDomain={{ min: highestSpeed - 100, max: lowestSpeed + 100 }}
            tickCount={5}
            lineChart={
              segments.length ? (
                <View style={CHART_CONFIG.lineStyle}>
                  <LineChart.Provider
                    data={segments.map((segment, index) => ({
                      timestamp: index,
                      value: segment.speed,
                    }))}
                    onCurrentIndexChange={(index) => {
                      if (Platform.OS !== "web") {
                        Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
                      }
                      onIndexChange({
                        index,
                        list: "segments",
                      });
                    }}
                    yRange={{ min: highestSpeed - 100, max: lowestSpeed + 100 }}
                  >
                    <PacevisorLineChart
                      graph="segments"
                      fullscreenWidth={fullscreenWidth}
                      {...props}
                    />
                  </LineChart.Provider>
                </View>
              ) : null
            }
          />
        </ErrorBoundary>
      )}

      <View style={CONTAINER(deviceOrientation)}>
        <Metrics
          selectedSegment={selectedSegment}
          type={race.type as RaceType}
        />
      </View>
    </View>
  );
});
