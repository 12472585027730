import { View } from "react-native";
import { Text } from "../../Text";
import { translate } from "swunitch-i18n";
import React from "react";
import {
  scrollTable,
  scrollTableCell,
  scrollTableRow,
} from "../../../theme/view-style";

export function RaceTableHeader() {
  return (
    <View style={scrollTable}>
      <View style={scrollTableRow}>
        <Text preset="bold" style={scrollTableCell(0.8)}>
          Km
        </Text>
        <Text preset="bold" style={scrollTableCell(0.6)}>
          D+
        </Text>
        <Text preset="bold" style={scrollTableCell(0.6)}>
          D-
        </Text>
        <Text preset="bold" style={scrollTableCell()}>
          Cumul D+
        </Text>
        <Text preset="bold" style={scrollTableCell()}>
          Cumul D-
        </Text>
        <Text preset="bold" style={scrollTableCell()}>
          Tmp/km
        </Text>
        <Text preset="bold" style={scrollTableCell()}>
          {translate("units.speed")}
        </Text>
        <Text preset="bold" style={scrollTableCell(1.5)}>
          Tmp cumul
        </Text>
        <Text preset="bold" style={scrollTableCell()}>
          {translate("units.kilometer", {
            count: 5,
          })}
        </Text>
        <Text preset="bold" style={scrollTableCell(1.5)}>
          {translate("units.kilometer", {
            count: 10,
          })}
        </Text>
      </View>
    </View>
  );
}
