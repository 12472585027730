import { AppDarkTheme, palette, spacing } from "../../theme";
import React from "react";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { AppStackParamList } from "../../navigators";
import { ImageBackground } from "expo-image";
import { Text } from "../../components/Text";
import { Card } from "../../components/Card";
import { Button } from "../../components/Button";
import { SMALL_BUTTON, SMALL_BUTTON_TEXT } from "../../theme/view-style";
import { observer } from "mobx-react-lite";
import { imageRegistry } from "../../components";

export const ReminderRace = observer(function ReminderRace() {
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>();

  return (
    <Card
      onPress={() => navigation.navigate("races")}
      style={{
        backgroundColor: palette.neutral900,
      }}
    >
      <ImageBackground
        source={imageRegistry.blueBackground}
        style={{
          justifyContent: "center",
          alignItems: "center",
          padding: spacing.extraSmall,
        }}
      >
        <Text
          preset="subheading"
          style={{ color: AppDarkTheme.colors.text, textAlign: "center" }}
          tx="racesScreen.cardTitle"
        />
        <Text
          size="xxs"
          style={{ color: AppDarkTheme.colors.text, textAlign: "center" }}
          tx="racesScreen.cardDescription"
        />
        <Button
          onPress={() => navigation.navigate("races")}
          preset="reversed"
          style={[SMALL_BUTTON, { marginTop: spacing.small }]}
          textStyle={SMALL_BUTTON_TEXT}
          tx="racesScreen.cardButton"
        />
      </ImageBackground>
    </Card>
  );
});
