import React, { useRef, useState } from "react";
import {
  KeyboardAvoidingView,
  KeyboardAvoidingViewProps,
  LayoutChangeEvent,
  Platform,
  ScrollView,
  ScrollViewProps,
  StyleProp,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import { Image } from "expo-image";
import { StatusBar, StatusBarProps } from "expo-status-bar";
import { Edge, useSafeAreaInsets } from "react-native-safe-area-context";
import { useScrollToTop } from "@react-navigation/native";
import { Text } from "./Text";
import Constants from "expo-constants";
import { useBrandTheme } from "../theme/use-brand-theme";
import { observer } from "mobx-react-lite";
import { useStores } from "../models";
import { spacing } from "../theme";
import { ScreenProvider, useScreen } from "./ScreenRefContext";
import { openUrl } from "../services/openUrl";
import { imageRegistry } from "./Icon";

interface BaseScreenProps {
  /**
   * Children components.
   */
  children?: React.ReactNode;
  /**
   * Style for the outer content container useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>;
  /**
   * Style for the inner content container useful for padding & margin.
   */
  contentContainerStyle?: StyleProp<ViewStyle>;
  /**
   * Override the default edges for the safe area.
   */
  safeAreaEdges?: Edge[];
  /**
   * Background color
   */
  backgroundColor?: string;
  footerBackgroundColor?: string;
  /**
   * Status bar setting. Defaults to dark.
   */
  statusBarStyle?: "light" | "dark";
  /**
   * By how much should we offset the keyboard? Defaults to 0.
   */
  keyboardOffset?: number;
  /**
   * Pass any additional props directly to the StatusBar component.
   */
  StatusBarProps?: StatusBarProps;
  /**
   * Pass any additional props directly to the KeyboardAvoidingView component.
   */
  KeyboardAvoidingViewProps?: KeyboardAvoidingViewProps;

  hideFooter?: boolean;
}

interface FixedScreenProps extends BaseScreenProps {
  preset?: "fixed";
}

interface ScrollScreenProps extends BaseScreenProps {
  preset?: "scroll";
  /**
   * Should keyboard persist on screen tap. Defaults to handled.
   * Only applies to scroll preset.
   */
  keyboardShouldPersistTaps?: "handled" | "always" | "never";
  /**
   * Pass any additional props directly to the ScrollView component.
   */
  ScrollViewProps?: ScrollViewProps;

  ref?: ScrollViewProps;
}

interface AutoScreenProps extends Omit<ScrollScreenProps, "preset"> {
  preset?: "auto";
  /**
   * Threshold to trigger the automatic disabling/enabling of scroll ability.
   * Defaults to `{ percent: 0.92 }`.
   */
  scrollEnabledToggleThreshold?: { percent?: number; point?: number };
}

export type ScreenProps =
  | ScrollScreenProps
  | FixedScreenProps
  | AutoScreenProps;

const isIos = Platform.OS === "ios";

function isNonScrolling(preset?: ScreenProps["preset"]) {
  return !preset || preset === "fixed";
}

function useAutoPreset(props: AutoScreenProps) {
  const { preset, scrollEnabledToggleThreshold } = props;
  const { percent = 0.92, point = 0 } = scrollEnabledToggleThreshold || {};

  const scrollViewHeight = useRef<number | null>(null);
  const scrollViewContentHeight = useRef<number | null>(null);
  const [scrollEnabled, setScrollEnabled] = useState(true);

  function updateScrollState() {
    if (
      scrollViewHeight.current === null ||
      scrollViewContentHeight.current === null
    )
      return;

    // check whether content fits the screen then toggle scroll state according to it
    const contentFitsScreen = (function () {
      if (point) {
        return (
          scrollViewContentHeight.current < scrollViewHeight.current - point
        );
      } else {
        return (
          scrollViewContentHeight.current < scrollViewHeight.current * percent
        );
      }
    })();

    // content is less than the size of the screen, so we can disable scrolling
    if (scrollEnabled && contentFitsScreen) setScrollEnabled(false);

    // content is greater than the size of the screen, so let's enable scrolling
    if (!scrollEnabled && !contentFitsScreen) setScrollEnabled(true);
  }

  function onContentSizeChange(w: number, h: number) {
    // update scroll-view content height
    scrollViewContentHeight.current = h;
    updateScrollState();
  }

  function onLayout(e: LayoutChangeEvent) {
    const { height } = e.nativeEvent.layout;
    // update scroll-view  height
    scrollViewHeight.current = height;
    updateScrollState();
  }

  // update scroll state on every render
  if (preset === "auto") updateScrollState();

  return {
    scrollEnabled: preset === "auto" ? scrollEnabled : true,
    onContentSizeChange,
    onLayout,
  };
}

export function useSafeAreaInsetPadding(safeAreaEdges?: Edge[]) {
  const insets = useSafeAreaInsets();

  const insetStyles: ViewStyle = {};
  safeAreaEdges?.forEach((edge: Edge) => {
    const paddingProp = `padding${edge.charAt(0).toUpperCase()}${edge.slice(
      1,
    )}`;
    insetStyles[paddingProp] = insets[edge];
  });

  return insetStyles;
}

function ScreenWithoutScrolling(props: ScreenProps) {
  const { style, contentContainerStyle, children } = props;
  return (
    <View style={[$outerStyle, style]}>
      <View style={[$innerStyle, contentContainerStyle]}>{children}</View>
    </View>
  );
}

function ScreenWithScrolling(props: ScreenProps) {
  const {
    children,
    keyboardShouldPersistTaps = "handled",
    contentContainerStyle,
    ScrollViewProps,
    style,
    hideFooter = false,
  } = props as ScrollScreenProps;
  const { ref } = useScreen();

  const { scrollEnabled, onContentSizeChange, onLayout } = useAutoPreset(
    props as AutoScreenProps,
  );

  // Add native behavior of pressing the active tab to scroll to the top of the content
  // More info at: https://reactnavigation.org/docs/use-scroll-to-top/
  useScrollToTop(ref);

  return (
    <ScrollView
      {...{ keyboardShouldPersistTaps, scrollEnabled, ref }}
      {...ScrollViewProps}
      onLayout={(e) => {
        onLayout(e);
        ScrollViewProps?.onLayout?.(e);
      }}
      onContentSizeChange={(w: number, h: number) => {
        onContentSizeChange(w, h);
        ScrollViewProps?.onContentSizeChange?.(w, h);
      }}
      style={[$outerStyle, ScrollViewProps?.style, style]}
      contentContainerStyle={[
        $innerStyle,
        ScrollViewProps?.contentContainerStyle,
        contentContainerStyle,
      ]}
    >
      {children}

      {!hideFooter && <Footer {...props} />}
    </ScrollView>
  );
}

export function Screen(props: ScreenProps) {
  const {
    backgroundColor,
    KeyboardAvoidingViewProps,
    keyboardOffset = 0,
    safeAreaEdges,
    StatusBarProps,
    statusBarStyle,
  } = props;

  const theme = useBrandTheme();
  const insetPadding = useSafeAreaInsetPadding(safeAreaEdges);

  return (
    <ScreenProvider>
      <View
        style={[
          $containerStyle,
          {
            backgroundColor: backgroundColor || theme.colors.backgroundApp,
          },
          insetPadding,
        ]}
      >
        <StatusBar style={statusBarStyle} {...StatusBarProps} />

        <KeyboardAvoidingView
          behavior={isIos ? "padding" : undefined}
          keyboardVerticalOffset={keyboardOffset}
          {...KeyboardAvoidingViewProps}
          style={[$keyboardAvoidingViewStyle, KeyboardAvoidingViewProps?.style]}
        >
          {isNonScrolling(props.preset) ? (
            <ScreenWithoutScrolling {...props} />
          ) : (
            <ScreenWithScrolling {...props} />
          )}
        </KeyboardAvoidingView>
      </View>
    </ScreenProvider>
  );
}

export const Footer = observer(function Footer(props: ScreenProps) {
  const theme = useBrandTheme();
  const { globalStore } = useStores();

  return (
    <View
      style={[
        {
          backgroundColor: props.footerBackgroundColor,
        },
        Platform.OS !== "web" && {
          paddingBottom: 1000,
          marginBottom: -1000,
        },
      ]}
    >
      <View
        style={{
          alignItems: "center",
          marginBottom: spacing.medium,
        }}
      >
        <TouchableOpacity onPress={() => openUrl("https://pacevisor.com")}>
          <Image
            source={
              theme.dark ? imageRegistry.logoLight : imageRegistry.logoDark
            }
            style={{
              width: 150,
              height: 50,
              resizeMode: "contain",
              alignSelf: "center",
            }}
          />
        </TouchableOpacity>

        <Text
          preset="default"
          style={{ opacity: 0.5 }}
          text={`${Constants.expoConfig?.version || "x.x.x"} - ${
            globalStore.migrationVersion
          }`}
        />
      </View>
    </View>
  );
});

const $containerStyle: ViewStyle = {
  flex: 1,
  height: "100%",
  width: "100%",
};

const $keyboardAvoidingViewStyle: ViewStyle = {
  flex: 1,
};

const $outerStyle: ViewStyle = {
  flex: 1,
  height: "100%",
  width: "100%",
};

const $innerStyle: ViewStyle = {
  justifyContent: "flex-start",
  alignItems: "stretch",
};
