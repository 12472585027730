import { Instance, types } from "mobx-state-tree";
import { AssertEqual, assertType } from "../services/are-types-equal";
import { WaypointDTO } from "../domain/race";

export const WaypointModel = types.model("Waypoint").props({
  title: types.string,
  distance: types.number,
  icon: types.maybeNull(types.string),
  tags: types.optional(types.array(types.string), []),
});

export interface Waypoint extends Instance<typeof WaypointModel> {}

assertType<AssertEqual<Omit<WaypointDTO, "tags">, Omit<Waypoint, "tags">>>();
