import React, { ReactNode } from "react";
import { observer } from "mobx-react-lite";
import { RunnerLocation, RunnerTracker } from "./ghost/RunnerTracker";
import * as TaskManager from "expo-task-manager";
import { TaskManagerTaskBody } from "expo-task-manager";
import * as Notifications from "expo-notifications";
import * as turf from "@turf/turf";
import { EnhancedSegment } from "../services/analysis/GeoJsonEnhancer";
import * as ExpoLocation from "expo-location";
import * as Linking from "expo-linking";
import { FeatureCollection, Geometry } from "@turf/helpers";
import { translate } from "swunitch-i18n";
import { formatNumber } from "../utils/formatNumber";
import { KilometerPerHour } from "../domain/speed-converters/KilometerPerHour";
import { getSpeedForRaceType } from "../utils/getSpeedForRaceType";
import { RaceType } from "../models/race/race-type";
import { PositionHistory } from "./ghost/Ghost";

const BACKGROUND_TASK_NAME = "background-location-tracking";

let tracker: RunnerTracker | null = null;
let type: RaceType | null = null;
let runnerLocation: RunnerLocation | null = null;
let positionHistory: PositionHistory[] = [];

TaskManager.defineTask(
  BACKGROUND_TASK_NAME,
  async ({
    data,
    error,
  }: TaskManagerTaskBody<{
    locations: ExpoLocation.LocationObject[];
  }>) => {
    if (error) {
      console.error("Erreur dans la tâche en arrière-plan : ", error);
      return;
    }

    if (data) {
      const { locations } = data;
      if (!locations || locations.length === 0 || !tracker) return;
      const location = locations[locations.length - 1];
      const currentPosition: turf.helpers.Position = [
        location.coords.longitude,
        location.coords.latitude,
      ];

      positionHistory = [
        ...positionHistory,
        ...locations.map((l) => ({
          coords: [l.coords.longitude, l.coords.latitude],
          timestamp: l.timestamp,
          speed:
            l.coords.speed === null
              ? 0
              : normalizeSpeedFromGeolocation(l.coords.speed * 3600),
        })),
      ].slice(-20);

      const currentRunnerLocation = tracker.locateRunner(
        currentPosition,
        runnerLocation?.distanceFromStart || 0,
      );
      const runnerSpeed = tracker.computeSpeedFromHistory(positionHistory);

      if (
        type &&
        runnerSpeed !== null &&
        currentRunnerLocation !== null &&
        currentRunnerLocation.segmentIndex !== runnerLocation?.segmentIndex
      ) {
        runnerLocation = currentRunnerLocation;

        Notifications.scheduleNotificationAsync({
          content: {
            title: translate("ghostScreen.notifTitle", {
              segment: formatNumber(
                KilometerPerHour.fromMeterPerHour(
                  currentRunnerLocation.segment.distance,
                ),
              ),
              segmentSpeed: getSpeedForRaceType(
                currentRunnerLocation.segment.speed,
                type,
              ),
              averageSpeed: getSpeedForRaceType(
                normalizeSpeedFromGeolocation(runnerSpeed),
                type,
              ),
            }),
            body: translate("ghostScreen.notifDescription", {
              elevationGain: formatNumber(
                currentRunnerLocation.segment.elevationGain,
              ),
              elevationLoss: formatNumber(
                currentRunnerLocation.segment.elevationLoss,
              ),
              percentAngle: formatNumber(
                currentRunnerLocation.segment.percentAngle,
              ),
            }),
          },
          trigger: null,
        });
      }
    }
  },
);

export const startBackgroundTracking = async (
  geoJson: FeatureCollection<Geometry>,
  enhancedSegments: EnhancedSegment[],
  _type: RaceType,
): Promise<Notifications.PermissionResponse> => {
  const backgroundPermission =
    await ExpoLocation.requestBackgroundPermissionsAsync();
  if (backgroundPermission.granted) {
    const notificationPermissions =
      await Notifications.requestPermissionsAsync();
    if (!notificationPermissions.granted) {
      // NOTE: not blocking for notifications permissions
      Linking.openSettings();
    }

    tracker = new RunnerTracker(geoJson, enhancedSegments);
    type = _type;
    runnerLocation = null;
    positionHistory = [];

    await ExpoLocation.startLocationUpdatesAsync(BACKGROUND_TASK_NAME, {
      accuracy: ExpoLocation.Accuracy.High,
      distanceInterval: 5,
      showsBackgroundLocationIndicator: true,
    });
  }

  return backgroundPermission;
};

export const stopBackgroundTracking = async () => {
  await ExpoLocation.stopLocationUpdatesAsync(BACKGROUND_TASK_NAME);

  tracker = null;
  type = null;
  runnerLocation = null;
  positionHistory = [];
};

export function normalizeSpeedFromGeolocation(speedMH: number) {
  if (speedMH >= 1000) {
    return speedMH;
  }

  return 0;
}

export const GeolocationWrapper = observer(function GeolocationWrapper(props: {
  children: ReactNode;
}) {
  return <>{props.children}</>;
});
