import { ScrollView } from "react-native";
import React from "react";

export function ScrollTable(props: { children: React.ReactNode }) {
  return (
    <ScrollView
      contentContainerStyle={{
        flexGrow: 1,
      }}
      horizontal={true}
      nestedScrollEnabled={true}
      bounces={false}
    >
      {props.children}
    </ScrollView>
  );
}
