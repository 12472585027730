import * as turf from "@turf/turf";
import { Feature, LineString } from "@turf/turf";
import { FeatureCollection, Geometry } from "@turf/helpers";

export interface MetadataPoint {
  lat: number;
  lon: number;
  distance: number;
}

export interface Metadata {
  highestPoint: number;
  lowestPoint: number;
  totalLength: number;
  averageAltitude: number;
  points: MetadataPoint[];
  center: { lat: number; lon: number };
}

export class GeoJsonMetadata {
  private route: Feature<LineString>;

  constructor(geoJson: FeatureCollection<Geometry>) {
    this.route = geoJson.features[0] as Feature<LineString>;
  }

  public get(): Metadata {
    let highestPoint = -Infinity;
    let lowestPoint = Infinity;
    let totalAltitude = 0;
    let pointCount = 0;

    turf.coordEach(this.route, (coord) => {
      const [_lon, _lat, ele] = coord;
      if (ele !== undefined) {
        highestPoint = Math.max(highestPoint, ele);
        lowestPoint = Math.min(lowestPoint, ele);
        totalAltitude += ele;
        pointCount++;
      }
    });

    const totalLength = turf.length(this.route, { units: "kilometers" });
    const averageAltitude = totalAltitude / pointCount;

    let totalDistance = 0;

    const center = turf.center(this.route).geometry.coordinates;
    return {
      highestPoint,
      lowestPoint,
      totalLength,
      averageAltitude,
      points: this.route.geometry.coordinates.map(([lon, lat], i) => {
        totalDistance += turf.distance(
          [lon, lat],
          this.route.geometry.coordinates[i - 1] ?? [lon, lat],
          { units: "meters" },
        );
        return {
          lat,
          lon,
          distance: totalDistance,
        };
      }),
      center: {
        lat: center[1],
        lon: center[0],
      },
    };
  }
}
