import { View } from "react-native";
import Badge from "@react-navigation/bottom-tabs/src/views/Badge";
import React from "react";
import { spacing } from "../../../theme";
import { Text } from "../../Text";

interface CircleBadgeProps {
  color: string;
  text: string;
}

export function CircleBadge(props: CircleBadgeProps) {
  const { color, text } = props;
  return (
    <View style={{ flexDirection: "row" }}>
      <Badge
        visible={true}
        style={{
          backgroundColor: color,
          alignSelf: "flex-start",
          marginRight: spacing.micro,
        }}
      />
      <Text size="xxs" preset="formHelper">
        {text === undefined ? "" : ` ${text}`}
      </Text>
    </View>
  );
}
