import { Keyboard, Platform, View } from "react-native";
import React, { useMemo } from "react";
import { Slider } from "@miblanchard/react-native-slider";
import { palette } from "../../theme";
import { translate } from "swunitch-i18n";
import { Text } from "../Text";
import { BOTTOM_SHEET_TEXT, CENTER_MIDDLE } from "../../theme/view-style";
import { useBrandTheme } from "../../theme/use-brand-theme";
import * as Haptics from "expo-haptics";
import { ConverterProps } from "./converters/utils/ConverterProps";
import { observer } from "mobx-react-lite";
import { formatNumber } from "../../utils/formatNumber";
import { MAS } from "../../services/MAS";
import { Tag } from "../Tag";
import { Distance } from "../../domain/race-calculator/Distance";

export const MASPercentComponent = observer(
  (props: ConverterProps & { distance?: Distance }) => {
    const { sport, distance } = props;
    const theme = useBrandTheme();

    const masPercent = useMemo(() => {
      if (distance === undefined) {
        return undefined;
      }

      return formatNumber(
        MAS.getPercentFromDistance(sport.aerobicCapacity, distance),
        1,
      );
    }, [sport.aerobicCapacity, distance]);

    function handlePercentage(percent: number) {
      Keyboard.dismiss();
      if (Platform.OS !== "web") {
        Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
      }

      sport.updateAerobicCapacityPercentage(percent);
    }

    return (
      <View>
        <View style={[CENTER_MIDDLE, { justifyContent: "space-between" }]}>
          <Text preset="subheading" style={BOTTOM_SHEET_TEXT}>
            {sport.aerobicCapacityPercentage}
            {translate("units.percent-mas")}
          </Text>

          {masPercent !== undefined && (
            <Tag
              onPress={() =>
                sport.updateAerobicCapacityPercentage(parseFloat(masPercent))
              }
            >
              <Text style={BOTTOM_SHEET_TEXT}>
                Reco. {masPercent}
                {translate("units.percent-mas")}
              </Text>
            </Tag>
          )}
        </View>

        <Slider
          containerStyle={{ height: 30 }}
          minimumValue={30}
          maximumValue={100}
          trackClickable={true}
          step={0.5}
          value={sport.aerobicCapacityPercentage}
          onValueChange={(v) => {
            if (v[0] !== undefined) {
              handlePercentage(v[0]);
            }
          }}
          minimumTrackTintColor={theme.colors.primary}
          maximumTrackTintColor={palette.neutral900 + "66"}
          thumbTintColor={theme.colors.primary}
          // @ts-ignore
          testID="slider"
        />
      </View>
    );
  },
);
