import { observer } from "mobx-react-lite";
import { RaceType } from "../../models/race/race-type";
import { Timeline } from "./triathlon/Timeline";
import React, { useEffect, useMemo, useState } from "react";
import { Sport } from "../../models/sport/sport";
import { useStores } from "../../models";
import { AppDarkTheme, spacing } from "../../theme";
import { TouchableOpacity, View, ViewStyle } from "react-native";
import { MinPerKilometerComponent } from "./converters/MinPerKilometerComponent";
import { KilometerPerHourComponent } from "./converters/KilometerPerHourComponent";
import Accordion from "react-native-collapsible/Accordion";
import { Text } from "../Text";
import { ObjectiveModeSelector } from "./ObjectiveModeSelector";
import { EffortComponent } from "./converters/EffortComponent";
import { MinPerHundredMeterComponent } from "./converters/MinPerHundredMeterComponent";
import { Card } from "../Card";
import {
  BOTTOM_SHEET_TEXT,
  CENTER_MIDDLE,
  CONTAINER,
  RACE_ANALYSIS_COMPONENT_COLORS,
} from "../../theme/view-style";
import { useOrientation } from "../../utils/orientation-style";
import { AbilitySlider } from "../race-analysis/AbilitySlider";
import { TextField } from "../TextField";
import { translate } from "swunitch-i18n";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { numberToAbility } from "../../utils/numberToAbility";
import { MASSelector } from "./MASSelector";
import { Distance } from "../../domain/race-calculator/Distance";

const HEADER_STYLE: ViewStyle = {
  marginVertical: spacing.small,
};

export const SportsComponent = observer(function SportsComponent({
  type,
  setType,
  hideMoreOptions,
  expandAdvanced,
  distance,
}: {
  type: RaceType | "all";
  setType: (type: RaceType | "all") => void;
  hideMoreOptions: boolean;
  expandAdvanced?: boolean;
  distance?: Distance;
}) {
  const { globalStore } = useStores();
  const [activeSections, setActiveSections] = useState<number[]>([]);
  const [activeSubSections, setActiveSubSections] = useState<number[]>([]);
  const deviceOrientation = useOrientation();
  const sport: Sport = useMemo(() => {
    if (type === "all") {
      return globalStore.getSportByRaceType(RaceType.Run);
    }
    return globalStore.getSportByRaceType(type);
  }, [type]);

  function updateWeight(text: string) {
    const w = Number(text);
    sport.updateWeight(w);
  }

  useEffect(() => {
    if (expandAdvanced) {
      setActiveSections([0]);
    }
  }, [expandAdvanced]);

  const CONTENT: {
    title: string;
    value: string;
    content: React.ReactNode;
  }[] = [
    ...(type === RaceType.Run
      ? [
          {
            title: translate("raceScreen.predict.mas"),
            value: sport.useAerobicCapacity
              ? translate("common.yes")
              : translate("common.no"),
            content: <MASSelector sport={sport} distance={distance} />,
          },
        ]
      : []),
    ...(type === RaceType.Run && sport.useAerobicCapacity
      ? []
      : [
          {
            title: translate("units.effort"),
            value: sport.effort.toString(),
            content: (
              <EffortComponent
                effort={sport.effort}
                onEffortChange={(e) => sport.updateEffort(e)}
                textColor={AppDarkTheme.colors.text}
              />
            ),
          },
        ]),
    {
      title: translate("raceScreen.predict.mode"),
      value: sport.isTimeObjective
        ? translate("segmented.timeObjective")
        : translate("segmented.distanceObjective"),
      content: <ObjectiveModeSelector sport={sport} />,
    },
    ...(type === RaceType.Bike
      ? [
          {
            title: translate("raceScreen.weight"),
            value: sport.weight.toString(),
            content: (
              <TextField
                onChangeText={updateWeight}
                defaultValue={sport.weight.toString()}
                keyboardType="numeric"
                style={BOTTOM_SHEET_TEXT}
              />
            ),
          },
        ]
      : []),
    ...(type !== RaceType.Swim
      ? [
          {
            title: translate("raceScreen.climberNote"),
            value: numberToAbility(sport.climberAbility),
            content: (
              <AbilitySlider
                onValueChange={(v) => sport.updateClimberAbility(v)}
                value={sport.climberAbility}
              />
            ),
          },
          {
            title: translate("raceScreen.descenderNote"),
            value: numberToAbility(sport.descenderAbility),
            content: (
              <AbilitySlider
                onValueChange={(v) => sport.updateDescenderAbility(v)}
                value={sport.descenderAbility}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <View style={{ gap: spacing.medium }}>
      {type !== "all" && (
        <>
          <View
            style={[
              CONTAINER(deviceOrientation),
              {
                flexDirection: "row",
                justifyContent: "space-between",
              },
            ]}
          >
            {type === RaceType.Run ? (
              <>
                <MinPerKilometerComponent sport={sport} />

                <KilometerPerHourComponent sport={sport} />
              </>
            ) : null}
            {type === RaceType.Bike ? (
              <KilometerPerHourComponent sport={sport} />
            ) : null}
            {type === RaceType.Swim ? (
              <MinPerHundredMeterComponent sport={sport} />
            ) : null}
          </View>

          {hideMoreOptions ? null : (
            <Card
              noShadow
              style={{
                marginHorizontal: spacing.tiny,
                backgroundColor: RACE_ANALYSIS_COMPONENT_COLORS.dark1,
              }}
            >
              <Accordion
                activeSections={activeSections}
                sections={[
                  {
                    title: translate("raceScreen.predict.advanced"),
                    icon: (
                      <MaterialCommunityIcons
                        name="chevron-down"
                        size={24}
                        color={AppDarkTheme.colors.text}
                      />
                    ),
                    content: (
                      <Card
                        noShadow
                        withBorder
                        style={{
                          ...CONTAINER(deviceOrientation),
                          marginHorizontal: spacing.tiny,
                          marginBottom: spacing.tiny,
                          backgroundColor: RACE_ANALYSIS_COMPONENT_COLORS.dark2,
                          borderColor: RACE_ANALYSIS_COMPONENT_COLORS.dark4,
                        }}
                      >
                        <Accordion
                          activeSections={activeSubSections}
                          sections={CONTENT}
                          touchableComponent={TouchableOpacity}
                          expandMultiple={false}
                          renderHeader={(section, _, _isActive) => (
                            <View
                              style={[
                                HEADER_STYLE,
                                CENTER_MIDDLE,
                                { justifyContent: "space-between" },
                              ]}
                            >
                              <Text
                                preset="formLabel"
                                style={{
                                  color: AppDarkTheme.colors.text,
                                }}
                              >
                                {section.title}
                              </Text>
                              <Text
                                style={{
                                  color: AppDarkTheme.colors.text,
                                }}
                              >
                                {section.value}
                              </Text>
                            </View>
                          )}
                          renderContent={(section, _, _isActive) => (
                            <View
                              style={{
                                marginVertical: spacing.small,
                              }}
                            >
                              {section.content}
                            </View>
                          )}
                          renderFooter={() => (
                            <View
                              style={{
                                borderBottomWidth: 1,
                                borderBottomColor:
                                  RACE_ANALYSIS_COMPONENT_COLORS.dark4,
                              }}
                            />
                          )}
                          onChange={setActiveSubSections}
                          renderAsFlatList={false}
                        />
                      </Card>
                    ),
                  },
                ]}
                touchableComponent={TouchableOpacity}
                expandMultiple={false}
                renderHeader={(section, _, _isActive) => (
                  <View
                    style={[
                      HEADER_STYLE,
                      CONTAINER(deviceOrientation),
                      CENTER_MIDDLE,
                      { justifyContent: "space-between" },
                    ]}
                  >
                    <Text
                      preset="subheading"
                      style={{
                        color: AppDarkTheme.colors.text,
                      }}
                    >
                      {section.title}
                    </Text>
                    {section.icon}
                  </View>
                )}
                renderContent={(section, _, _isActive) => (
                  <View>{section.content}</View>
                )}
                onChange={setActiveSections}
                renderAsFlatList={false}
              />
            </Card>
          )}

          {hideMoreOptions ? (
            <View style={CONTAINER(deviceOrientation)}>
              <EffortComponent
                effort={sport.effort}
                onEffortChange={(e) => sport.updateEffort(e)}
              />
            </View>
          ) : null}
        </>
      )}
      {type === "all" && (
        <View style={CONTAINER(deviceOrientation)}>
          <Timeline onChangeType={(t) => setType(t)} />
        </View>
      )}
    </View>
  );
});
