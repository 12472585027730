import {
  NativeSyntheticEvent,
  TextInputFocusEventData,
  TextInputProps,
} from "react-native";
import { useEffect, useState } from "react";

export function selectTextOnFocus(
  value: string,
  onFocus: () => void = () => null,
): TextInputProps {
  const [initialFocus, setInitialFocus] = useState(false);

  useEffect(() => {
    if (initialFocus) {
      setTimeout(() => {
        setInitialFocus(false);
      }, 100);
    }
  }, [initialFocus]);

  return {
    onFocus: (_: NativeSyntheticEvent<TextInputFocusEventData>) => {
      setInitialFocus(true);
      onFocus();
    },
    // NOTE: Android and web don't struggle if selection is set then unset. But iOS will blur it and we lose the selection.
    selection: initialFocus ? { start: 0, end: value.length } : undefined,
  };
}
