import { Segment } from "./GeoJsonSegmenter";
import { BikeAdjuster } from "./BikeAdjuster";
import { RunAdjuster } from "./RunAdjuster";
import { RaceType } from "../../models/race/race-type";

export interface AdjusterConstructor {
  climberAbility: number;
  descenderAbility: number;
  baseSpeed: number;
  weight: number;
}

export class GeoJsonEnhancer {
  private segments: Segment[];
  private lapIndices: number[];
  private speedAdjuster: BikeAdjuster | RunAdjuster;
  private targetTime: number | null;

  constructor(
    segments: Segment[],
    lapIndices: number[],
    raceType: RaceType,
    data: AdjusterConstructor,
    objectiveTime: boolean,
  ) {
    this.segments = segments;
    this.lapIndices = lapIndices.sort((a, b) => a - b);
    this.speedAdjuster =
      raceType === RaceType.Bike
        ? new BikeAdjuster(data)
        : new RunAdjuster(data);
    const totalDistance = this.segments.reduce(
      (sum, segment) => sum + segment.length,
      0,
    );
    this.targetTime = objectiveTime
      ? (totalDistance / data.baseSpeed) * 3600
      : null;
  }

  public enhanceSegments(): EnhancedSegment[] {
    const timedSegments = this.addTime();
    if (this.targetTime) {
      this.adjustSpeedsForTargetTime(timedSegments);
    }
    return this.addCumulative(timedSegments);
  }

  private addTime(): TimedSegment[] {
    const enhancedSegments: TimedSegment[] = [];

    for (const segment of this.segments) {
      const speed = this.speedAdjuster.getSpeedAdjustment(segment);
      const timeInSeconds = (segment.length / speed) * 3600;

      enhancedSegments.push({
        ...segment,
        time: isFinite(timeInSeconds) ? timeInSeconds : 0,
        speed: isFinite(speed) ? speed : 0,
      });
    }

    return enhancedSegments;
  }

  private adjustSpeedsForTargetTime(segments: TimedSegment[]): void {
    const currentTotalTime = segments.reduce(
      (sum, segment) => sum + segment.time,
      0,
    );
    const adjustmentFactor = currentTotalTime / this.targetTime!;
    const maxSpeedMs = 24;

    let totalAdjustedTime = 0;

    segments.forEach((segment) => {
      segment.speed *= adjustmentFactor;
      if (segment.speed > maxSpeedMs * 3.6 * 1000) {
        segment.speed = maxSpeedMs * 3.6 * 1000;
      }
      segment.time = (segment.length / segment.speed) * 3600;
      totalAdjustedTime += segment.time;
    });

    const finalAdjustmentFactor = this.targetTime! / totalAdjustedTime;
    segments.forEach((segment) => {
      segment.speed *= finalAdjustmentFactor;
      segment.time = (segment.length / segment.speed) * 3600;
    });
  }

  private addCumulative(segments: TimedSegment[]): EnhancedSegment[] {
    let cumulativeTime = 0;
    let segmentCount = 0;
    const lapTimeCounters: Record<number, number> = {};

    this.lapIndices.forEach((lapIndex) => {
      lapTimeCounters[lapIndex] = 0;
    });

    const enhancedSegments: EnhancedSegment[] = [];

    for (const segment of segments) {
      cumulativeTime += segment.time;
      segmentCount++;

      this.lapIndices.forEach((lapIndex) => {
        lapTimeCounters[lapIndex] += segment.time;
      });

      const lapTimes: Record<number, number> = {};
      this.lapIndices.forEach((lapIndex) => {
        if (segmentCount % lapIndex === 0) {
          lapTimes[lapIndex] = lapTimeCounters[lapIndex];
          lapTimeCounters[lapIndex] = 0;
        }
      });

      enhancedSegments.push({
        ...segment,
        cumulativeTime,
        lapTimes: Object.keys(lapTimes).length > 0 ? lapTimes : null,
        coordinates: segment.coordinates,
      });
    }

    return enhancedSegments;
  }
}

interface TimedSegment extends Segment {
  time: number;
  speed: number;
}

export interface EnhancedSegment extends TimedSegment {
  cumulativeTime: number;
  lapTimes: Record<number, number> | null;
}
