import { applySnapshot, Instance, types } from "mobx-state-tree";
import { DistanceSize } from "../../domain/race-calculator/DistanceSize";
import { Speed } from "../../domain/race-calculator/Speed";
import { getRootStore } from "../helpers/getRootStore";
import { DistanceModel } from "../distance/distance";
import { v4 as uuid } from "uuid";
import { Race } from "../race/race";
import { TimeConverter } from "../../domain/race-calculator/TimeConverter";
import { Time } from "../../domain/race-calculator/Time";
import { Distance } from "../../domain/race-calculator/Distance";
import { RaceType } from "../race/race-type";

export const SportModel = types
  .model("Sport")
  .props({
    speed: types.number,
    effort: types.number,
    distance: DistanceModel,
    distanceSize: types.enumeration<DistanceSize>(Object.values(DistanceSize)),
    type: types.optional(
      types.enumeration<RaceType>(Object.values(RaceType)),
      RaceType.Run,
    ),
    useAerobicCapacity: types.optional(types.boolean, false),
    aerobicCapacity: types.optional(types.number, 15000),
    aerobicCapacityPercentage: types.optional(types.number, 80),
    descenderAbility: types.optional(types.number, 1),
    climberAbility: types.optional(types.number, 1),
    distances: types.optional(types.array(DistanceModel), []),
    weight: types.optional(types.number, 75),
    transitionTime: types.optional(types.number, 60),
    isTimeObjective: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get domainSpeed(): Speed {
      const root = getRootStore(self);

      return Speed.of(
        self.speed,
        self.effort,
        self.distanceSize,
        root.globalStore.isMetricSystem,
      );
    },
  }))
  .actions((self) => ({
    getTimeForDistance: function (): Time {
      const time = TimeConverter.toDomain(
        self.distance.domainDistance.getInSmallSize(),
        self.domainSpeed.getInAdaptedDistanceSize(),
      );
      return time;
    },
    updateIsTimeObjective: function (isTimeObjective: boolean) {
      self.isTimeObjective = isTimeObjective;
    },
    updateSpeed: function (speed: number) {
      self.speed = speed;

      // NOTE: can be modified from training screen, so reset aerobic capacity
      self.useAerobicCapacity = false;
    },
    updateEffort: function (effort: number) {
      self.effort = effort;

      // NOTE: can be modified from training screen, so reset aerobic capacity
      self.useAerobicCapacity = false;
    },
    updateClimberAbility: function (climberAbility: number) {
      self.climberAbility = climberAbility;
    },
    updateDescenderAbility: function (descenderAbility: number) {
      self.descenderAbility = descenderAbility;
    },
    updateWeight: function (weight: number) {
      if (weight > 30 && weight < 200) {
        self.weight = weight;
      }
    },
    addDistances: function (id: string) {
      const index = self.distances.findIndex((d) => d.id === id);
      self.distances.splice(
        index + 1,
        0,
        DistanceModel.create({
          id: uuid(),
          value: 0,
          distanceSize: self.distanceSize,
        }),
      );
    },
    updateTransitionTime: function (transitionTime: number) {
      self.transitionTime = transitionTime;
    },
    removeDistances: function (id: string) {
      self.distances.replace(self.distances.filter((d) => d.id !== id));
    },

    updateAerobicCapacity: function (ac: number) {
      self.aerobicCapacity = ac;
      this._inferSpeed();
    },
    updateUseAerobicCapacity: function (useAerobicCapacity: boolean) {
      self.useAerobicCapacity = useAerobicCapacity;
      this._inferSpeed();
    },
    updateAerobicCapacityPercentage: function (
      aerobicCapacityPercentage: number,
    ) {
      self.aerobicCapacityPercentage = aerobicCapacityPercentage;
      this._inferSpeed();
    },
    _inferSpeed: function () {
      if (!self.useAerobicCapacity) {
        return;
      }

      const root = getRootStore(self);
      self.effort = 100;
      self.speed = Speed.of(
        self.aerobicCapacity,
        self.aerobicCapacityPercentage,
        self.distanceSize,
        root.globalStore.isMetricSystem,
      ).getInAdaptedDistanceSize();
    },

    _set: function (sport: Sport) {
      applySnapshot(self, sport);
    },
  }));

export function getRaceDomainDistance(
  race: Race,
  isMetricSystem: boolean,
): Distance {
  return race.type === "swim"
    ? Distance.of(race.distance, DistanceSize.short, isMetricSystem)
    : Distance.detect(race.distance, isMetricSystem);
}

export interface Sport extends Instance<typeof SportModel> {}
