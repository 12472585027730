import React, { useEffect, useMemo, useState } from "react";
import {
  RefreshControl,
  StyleSheet,
  useWindowDimensions,
  View,
} from "react-native";
import { ImageBackground } from "expo-image";
import { observer } from "mobx-react-lite";
import { CONTAINER } from "../../theme/view-style";
import { Card, imageRegistry, Screen, Text } from "../index";
import { AppDarkTheme, palette, spacing } from "../../theme";
import { AppStackParamList } from "../../navigators";
import { Platform } from "expo-modules-core";
import { Foundation, MaterialCommunityIcons } from "@expo/vector-icons";
import { useStores } from "../../models";
import {
  AngleType,
  GeoJsonSegmenter,
} from "../../services/analysis/GeoJsonSegmenter";
import {
  EnhancedSegment,
  GeoJsonEnhancer,
} from "../../services/analysis/GeoJsonEnhancer";
import {
  GeoJsonMetadata,
  Metadata,
} from "../../services/analysis/GeoJsonMetadata";
import { Race } from "../../models/race/race";
import { translate } from "swunitch-i18n";
import { useDebounceEffect } from "ahooks";
import { useOrientation } from "../../utils/orientation-style";
import { TimeConverter } from "../../domain/race-calculator/TimeConverter";
import { useNavigation } from "@react-navigation/native";
import { formatNumber } from "../../utils/formatNumber";
import { StackNavigationProp } from "@react-navigation/stack";
import { getRaceDomainDistance, Sport } from "../../models/sport/sport";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { Statistics } from "./Statistics";
import { defaultSegment } from "./defaultSegment";
import { RaceTitle } from "./RaceTitle";
import { RaceTable } from "./table/RaceTable";
import { RaceForecast } from "./RaceForecast";
import { RaceType } from "../../models/race/race-type";
import { RaceCTA } from "./RaceCTA";
import { SportsComponent } from "../sports/SportsComponent";
import { TimeEdition } from "../sports/converters/TimeEdition";
import { RaceMap } from "./RaceMap";
import { RaceGraphs } from "./graphs/RaceGraphs";
import { Ghost } from "../ghost/Ghost";
import { RunnerLocation } from "../ghost/RunnerTracker";
import { CircleBadge } from "./table/CircleBadge";
import { ScrollTable } from "../ScrollTable";
import { Time } from "../../domain/race-calculator/Time";

interface RaceAnalysisProps {
  race: Race;
  isInIframe: boolean;
  showAllRoadbook: boolean;
}

export const RaceAnalysis = observer(function RaceAnalysis(
  props: RaceAnalysisProps,
) {
  const { race, isInIframe, showAllRoadbook } = props;
  const theme = useBrandTheme();
  const { colors } = theme;
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>();
  const [selectedSegment, setSelectedSegment] =
    useState<EnhancedSegment>(defaultSegment);
  const { globalStore } = useStores();
  const [totalHeight, setTotalHeight] = useState(0);
  const layout = useWindowDimensions();
  const deviceOrientation = useOrientation();
  const [runnerPosition, setRunnerPosition] = useState<RunnerLocation | null>(
    null,
  );
  const [segments, setSegments] = useState<EnhancedSegment[]>([]);
  const [profile, setProfile] = useState<EnhancedSegment[]>([]);
  const [metadata, setMetadata] = useState<Metadata | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [canScroll, setCanScroll] = useState(true);
  const sport: Sport = useMemo(
    () => globalStore.getSportByRaceType(race.type as RaceType),
    [race.type],
  );
  const [mapFullScreen, setMapFullScreen] = useState(false);
  const isLargeScreen = useMemo(() => {
    return layout.width > 900;
  }, [layout.width]);
  const isLargeScreenMapSection = useMemo(() => {
    return layout.width > 900 && !mapFullScreen;
  }, [layout.width, mapFullScreen]);
  const [finalTime, finalHumanTime] = useMemo((): [Time, string] => {
    const t = TimeConverter.fromSecondsToDomain(
      segments[segments.length - 1]?.cumulativeTime || 0,
    );
    return [t, TimeConverter.toHuman(t)];
  }, [segments]);
  const distance = useMemo(() => {
    return getRaceDomainDistance(race, globalStore.isMetricSystem);
  }, [race.distance, globalStore.isMetricSystem]);

  useEffect(() => {
    if (Platform.OS === "web") {
      window.parent.postMessage(
        {
          type: "setHeight",
          id: race.id,
          height: totalHeight + 80,
        },
        "*",
      );
    }
  }, [totalHeight]);

  useEffect(() => {
    navigation.setOptions({
      headerShown: !isInIframe,
      title: race.title,
    });
  }, [isInIframe, race]);

  useEffect(() => {
    if (race && typeof race.fetchGeoJson === "function") {
      race.fetchGeoJson();
    }
  }, [race]);

  useDebounceEffect(
    () => {
      if (race.geoJson && typeof race.fetchGeoJson === "function") {
        setIsLoading(true);
        const geoJson = race.geoJsonParsed!;
        const geoJsonSegmenter = new GeoJsonSegmenter(geoJson, race.waypoints);

        const segments = geoJsonSegmenter.segmentTrail(1000);
        const profile = geoJsonSegmenter.segmentTrail(500);

        const enhancedSegments = new GeoJsonEnhancer(
          segments,
          [5, 10],
          race.type as RaceType,
          {
            climberAbility: sport.climberAbility,
            descenderAbility: sport.descenderAbility,
            baseSpeed: sport.domainSpeed.getSpeedWithEffort(),
            weight: sport.weight,
          },
          sport.isTimeObjective,
        ).enhanceSegments();
        const enhancedProfile = new GeoJsonEnhancer(
          profile,
          [5, 10],
          race.type as RaceType,
          {
            climberAbility: sport.climberAbility,
            descenderAbility: sport.descenderAbility,
            baseSpeed: sport.domainSpeed.getSpeedWithEffort(),
            weight: sport.weight,
          },
          sport.isTimeObjective,
        ).enhanceSegments();
        const metadata = new GeoJsonMetadata(geoJson).get();

        setProfile(enhancedProfile);
        setSegments(enhancedSegments);
        setMetadata(metadata);
        setIsLoading(false);
      }
    },
    [
      race.geoJson,
      sport.speed,
      sport.effort,
      sport.descenderAbility,
      sport.climberAbility,
      sport.weight,
      sport.isTimeObjective,
    ],
    {
      wait: 500,
    },
  );

  return (
    <Screen
      hideFooter={isInIframe}
      preset="scroll"
      backgroundColor={Platform.OS === "web" ? colors.transparent : undefined}
      ScrollViewProps={{
        scrollEnabled: canScroll,
        refreshControl: (
          <RefreshControl refreshing={isLoading} onRefresh={() => null} />
        ),
      }}
    >
      <View
        {...(Platform.OS === "web"
          ? {
              onLayout: (e) => {
                setTotalHeight(e.nativeEvent.layout.height);
              },
            }
          : {})}
      >
        <Card style={{ paddingTop: spacing.medium }}>
          <RaceTitle race={race} />

          <RaceCTA race={race} />
        </Card>

        <View
          style={{
            flexDirection: isLargeScreen ? "row" : "column",
          }}
        >
          <View style={isLargeScreen ? { flex: 1 } : {}}>
            <Card paddingVertical={true}>
              <View style={CONTAINER(deviceOrientation)}>
                <Text preset="subheading">
                  {translate("raceScreen.mainInfo")}
                </Text>

                <Statistics
                  width="32.5%"
                  statistics={[
                    {
                      value: translate("units.kilometer", {
                        count: formatNumber(metadata?.totalLength || 0),
                      }),
                      iconText: translate("units.distance"),
                      icon: (
                        <MaterialCommunityIcons
                          name="arrow-left-right"
                          size={15}
                          color={theme.colors.textDim}
                        />
                      ),
                    },
                    {
                      value: translate("raceScreen.elevation", {
                        value: formatNumber(
                          segments[segments.length - 1]
                            ?.cumulativeElevationGain || 0,
                        ),
                      }),
                      iconText: translate("units.elevationGain"),
                      icon: (
                        <MaterialCommunityIcons
                          name="arrow-top-right"
                          size={15}
                          color={theme.colors.textDim}
                        />
                      ),
                    },
                    {
                      value: translate("raceScreen.elevation", {
                        value: formatNumber(
                          segments[segments.length - 1]
                            ?.cumulativeElevationLoss || 0,
                        ),
                      }),
                      iconText: translate("units.elevationLoss"),
                      icon: (
                        <MaterialCommunityIcons
                          name="arrow-bottom-right"
                          size={15}
                          color={theme.colors.textDim}
                        />
                      ),
                    },
                    {
                      value: translate("raceScreen.elevation", {
                        value: formatNumber(metadata?.highestPoint || 0),
                      }),
                      iconText: translate("units.elevationMax"),
                      icon: (
                        <Foundation
                          name="mountains"
                          size={15}
                          color={theme.colors.textDim}
                        />
                      ),
                    },
                    {
                      value: translate("raceScreen.elevation", {
                        value: formatNumber(metadata?.lowestPoint || 0),
                      }),
                      iconText: translate("units.elevationMin"),
                      icon: (
                        <Foundation
                          name="mountains"
                          size={15}
                          color={theme.colors.textDim}
                        />
                      ),
                    },
                    {
                      value: translate("raceScreen.elevation", {
                        value: formatNumber(metadata?.averageAltitude || 0),
                      }),
                      iconText: translate("units.elevationAvg"),
                      icon: (
                        <Foundation
                          name="mountains"
                          size={15}
                          color={theme.colors.textDim}
                        />
                      ),
                    },
                  ]}
                />
              </View>
            </Card>
          </View>

          {race.forecasts.length > 0 && (
            <View style={isLargeScreen ? { flex: 1 } : {}}>
              <Card paddingVertical={true}>
                <RaceForecast race={race} />
              </Card>
            </View>
          )}
        </View>

        <View
          style={{
            flexDirection: isLargeScreenMapSection ? "row" : "column",
          }}
        >
          <View style={isLargeScreenMapSection ? { flex: 1 } : {}}>
            <Card>
              <ImageBackground
                source={imageRegistry.blueBackground}
                style={{
                  ...StyleSheet.absoluteFillObject,
                }}
              />
              <View
                style={{
                  paddingTop: spacing.medium,
                  paddingBottom: spacing.tiny,
                }}
              >
                <View style={CONTAINER(deviceOrientation)}>
                  <Text
                    preset="subheading"
                    style={{
                      color: AppDarkTheme.colors.text,
                    }}
                  >
                    {translate("raceScreen.predict.edit")}
                  </Text>

                  <Text
                    tx="tourGuide.raceTimeSmall"
                    txOptions={{ time: finalHumanTime }}
                    size="xs"
                    style={{
                      color: AppDarkTheme.colors.text,
                      marginBottom: spacing.tiny,
                    }}
                  />
                </View>

                <SportsComponent
                  type={race.type as RaceType}
                  setType={() => null}
                  hideMoreOptions={false}
                  distance={distance}
                />
              </View>
            </Card>

            <Card paddingVertical={true}>
              <View style={CONTAINER(deviceOrientation)}>
                <Text preset="subheading">
                  {translate("raceScreen.predict.totalTime")}
                </Text>

                <TimeEdition
                  layout="big"
                  textColor={theme.colors.text}
                  time={finalTime}
                  disabled={true}
                  backgroundColor={theme.colors.lightTransparent}
                  onTimeChange={() => null}
                />
              </View>
            </Card>

            {race.geoJson && typeof race.fetchGeoJson === "function" ? (
              <Card
                paddingVertical={true}
                withBorder
                style={{
                  borderColor: theme.colors.primary,
                  borderWidth: 3,
                }}
              >
                <View style={CONTAINER(deviceOrientation)}>
                  <Text preset="subheading">
                    {translate("ghostScreen.title")}
                  </Text>
                  <Text
                    tx="ghostScreen.description"
                    size="xxs"
                    style={{
                      color: theme.colors.textDim,
                    }}
                  />

                  <Ghost
                    enhancedSegments={segments}
                    geoJson={race.geoJsonParsed!}
                    onPositionChange={(position) => setRunnerPosition(position)}
                    type={race.type as RaceType}
                  />
                </View>
              </Card>
            ) : null}
          </View>

          <View style={isLargeScreenMapSection ? { flex: 1 } : {}}>
            <Card>
              <RaceMap
                selectedSegment={selectedSegment}
                metadata={metadata}
                profile={profile}
                onMoveStart={() => setCanScroll(false)}
                onMoveEnd={() => setCanScroll(true)}
                runnerPosition={runnerPosition}
                countryCode={race.countryCode || "FR"}
                isFullscreen={mapFullScreen}
                onFullscreen={(f) => setMapFullScreen(f)}
              />

              <RaceGraphs
                race={race}
                profile={profile}
                segments={segments}
                selectedSegment={selectedSegment}
                onSegmentSelected={(segment) => setSelectedSegment(segment)}
                isLargeScreen={isLargeScreenMapSection}
              />
            </Card>
          </View>
        </View>

        <Card
          paddingVertical={true}
          isLast={true}
          style={{ marginBottom: spacing.medium }}
        >
          <View style={CONTAINER(deviceOrientation)}>
            <Text preset="subheading">Roadbook</Text>
            <Text
              tx="raceScreen.roadbookExplanation"
              size="xxs"
              style={{
                color: theme.colors.textDim,
              }}
            />

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginVertical: spacing.extraSmall,
              }}
            >
              <CircleBadge
                text={translate("raceScreen.waypoint")}
                color={theme.colors.info}
              />
              <CircleBadge
                text={translate("raceScreen.percentAngleBadge", {
                  value: AngleType.LOW,
                })}
                color={palette.low}
              />
              <CircleBadge
                text={translate("raceScreen.percentAngleBadge", {
                  value: AngleType.MEDIUM,
                })}
                color={palette.medium}
              />
              <CircleBadge
                text={translate("raceScreen.percentAngleBadge", {
                  value: AngleType.HIGH,
                })}
                color={palette.high}
              />
            </View>
          </View>

          <ScrollTable>
            <RaceTable
              segments={segments}
              showAll={showAllRoadbook}
              onSegmentSelected={(segment) => setSelectedSegment(segment)}
            />
          </ScrollTable>
        </Card>
      </View>
    </Screen>
  );
});
