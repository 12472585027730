import { View } from "react-native";
import React, { useMemo } from "react";
import { BOTTOM_SHEET_TEXT, UNIT_DISPOSITION } from "../../../theme/view-style";
import { translate } from "swunitch-i18n";
import { ConverterProps } from "./utils/ConverterProps";
import { sanitizeKm } from "../../../domain/race-calculator/sanitize-km";
import { Text } from "../../Text";
import { KilometerPerHour } from "../../../domain/speed-converters/KilometerPerHour";
import { formatNumber } from "../../../utils/formatNumber";
import { observer } from "mobx-react-lite";
import { EditableConverter } from "./utils/EditableConverter";

export const MASEdition = observer(function MASEdition(props: ConverterProps) {
  const { sport, disabled } = props;

  const masKmh = useMemo(
    () => KilometerPerHour.fromMeterPerHour(sport.aerobicCapacity),
    [sport.aerobicCapacity],
  );

  function handleKmh(s: number) {
    sport.updateAerobicCapacity(KilometerPerHour.toMeterPerHour(s));
  }

  return (
    <View>
      <Text preset="subheading" style={BOTTOM_SHEET_TEXT}>
        {translate("units.mas")}
      </Text>

      <View style={UNIT_DISPOSITION}>
        <EditableConverter
          mask={[/[\d.,]/, /[\d.,]/, /[\d.,]/, /[\d.,]/, /[\d.,]/]}
          onValueChange={(v) => handleKmh(sanitizeKm(v))}
          value={formatNumber(masKmh)}
          testID="mas"
          disabled={disabled}
        />
      </View>
    </View>
  );
});
