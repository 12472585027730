import * as turf from "@turf/turf";
import { Feature, LineString } from "@turf/turf";
import { FeatureCollection, Geometry } from "@turf/helpers";
import { Waypoint } from "../../models/waypoint";

export class GeoJsonSegmenter {
  private route: Feature<LineString>;
  private waypoints: Waypoint[];

  constructor(geoJson: FeatureCollection<Geometry>, waypoints: Waypoint[]) {
    this.route = geoJson.features[0] as Feature<LineString>;
    this.waypoints = waypoints;
  }

  public segmentTrail(segmentLength: number): Segment[] {
    const segments: Segment[] = [];
    const coordinates = this.route.geometry.coordinates;

    let totalDistance = 0;
    let elevationGain = 0;
    let elevationLoss = 0;
    let cumulativeElevationGain = 0;
    let cumulativeElevationLoss = 0;
    let lastAltitude = coordinates[0][2] ?? 0;
    let currentPoint = turf.point(coordinates[0]);
    let startPoint = turf.point(coordinates[0]);

    for (let i = 1; i < coordinates.length; i++) {
      currentPoint = turf.point(coordinates[i]);
      const previousPoint = turf.point(coordinates[i - 1]);
      const distance = turf.distance(previousPoint, currentPoint, {
        units: "meters",
      });
      const currentAltitude =
        currentPoint.geometry.coordinates[2] ?? lastAltitude;
      const { elevationGain: gain, elevationLoss: loss } =
        this.calculateElevation(currentAltitude, lastAltitude);

      totalDistance += distance;
      elevationGain += gain;
      elevationLoss += loss;
      cumulativeElevationGain += gain;
      cumulativeElevationLoss += loss;
      lastAltitude = currentAltitude;

      while (totalDistance >= segmentLength) {
        const segmentElevationChange = elevationGain - elevationLoss;
        const percentAngle = (segmentElevationChange / segmentLength) * 100;

        const segmentName = segments.length * segmentLength + segmentLength;
        segments.push({
          distance: segmentName,
          length: segmentLength,
          elevationGain,
          elevationLoss,
          cumulativeElevationGain,
          cumulativeElevationLoss,
          altitude: lastAltitude,
          percentAngle,
          startCoordinates:
            segmentName <= 1000
              ? [...startPoint.geometry.coordinates]
              : undefined,
          coordinates: [...currentPoint.geometry.coordinates],
          waypoints: this.getWaypointsInSegment(
            segmentName - segmentLength,
            segmentName,
          ).map((waypoint) => this.getEnhancedWaypoint(waypoint)),
        });
        totalDistance -= segmentLength;
        elevationGain = 0;
        elevationLoss = 0;
        startPoint = currentPoint;
      }
    }

    if (totalDistance > 0) {
      const segmentElevationChange = elevationGain - elevationLoss;
      const percentAngle = (segmentElevationChange / totalDistance) * 100;

      const segmentName = segments.length * segmentLength + totalDistance;
      segments.push({
        distance: segmentName,
        length: totalDistance,
        elevationGain,
        elevationLoss,
        cumulativeElevationGain,
        cumulativeElevationLoss,
        altitude: lastAltitude,
        percentAngle,
        coordinates: [...currentPoint.geometry.coordinates],
        waypoints: this.getWaypointsInSegment(
          segmentName - totalDistance,
          segmentName,
        ).map((waypoint) => this.getEnhancedWaypoint(waypoint)),
      });
    }

    return segments;
  }

  public getEnhancedWaypoint(waypoint: Waypoint): EnhancedWaypoint {
    const coordinates = this.route.geometry.coordinates;

    let totalDistance = 0;
    let elevationGain = 0;
    let elevationLoss = 0;
    let lastAltitude = coordinates[0][2] ?? 0;
    let cumulativeElevationGain = 0;
    let cumulativeElevationLoss = 0;

    for (let i = 1; i < coordinates.length; i++) {
      const currentPoint = turf.point(coordinates[i]);
      const previousPoint = turf.point(coordinates[i - 1]);
      const distance = turf.distance(previousPoint, currentPoint, {
        units: "meters",
      });
      const currentAltitude =
        currentPoint.geometry.coordinates[2] ?? lastAltitude;
      const { elevationGain: gain, elevationLoss: loss } =
        this.calculateElevation(currentAltitude, lastAltitude);

      totalDistance += distance;
      elevationGain = gain;
      elevationLoss = loss;
      cumulativeElevationGain += gain;
      cumulativeElevationLoss += loss;
      lastAltitude = currentAltitude;

      if (totalDistance >= waypoint.distance) {
        return {
          ...waypoint,
          altitude: lastAltitude,
          elevationGain,
          elevationLoss,
          cumulativeElevationGain,
          cumulativeElevationLoss,
          percentAngle: elevationGain
            ? (elevationGain / waypoint.distance) * 100
            : 0,
          coordinates: currentPoint.geometry.coordinates,
        };
      }

      lastAltitude = coordinates[i][2] ?? lastAltitude;
    }

    return {
      ...waypoint,
      altitude: lastAltitude,
      elevationGain: 0,
      elevationLoss: 0,
      cumulativeElevationGain,
      cumulativeElevationLoss,
      percentAngle: 0,
      coordinates: coordinates[coordinates.length - 1],
    };
  }

  private calculateElevation(currentAltitude: number, lastAltitude: number) {
    const elevationGain =
      currentAltitude > lastAltitude ? currentAltitude - lastAltitude : 0;
    const elevationLoss =
      currentAltitude < lastAltitude ? lastAltitude - currentAltitude : 0;

    return { elevationGain, elevationLoss };
  }

  private getWaypointsInSegment(
    startDistance: number,
    endDistance: number,
  ): Waypoint[] {
    return this.waypoints.filter(
      (waypoint) =>
        waypoint.distance >= startDistance && waypoint.distance < endDistance,
    );
  }
}

export interface EnhancedWaypoint extends Waypoint {
  altitude: number;
  elevationGain: number;
  elevationLoss: number;
  percentAngle: number;
  cumulativeElevationGain: number;
  cumulativeElevationLoss: number;
  coordinates: number[];
}

export interface Segment {
  distance: number;
  length: number;
  elevationGain: number;
  elevationLoss: number;
  cumulativeElevationGain: number;
  cumulativeElevationLoss: number;
  altitude: number;
  percentAngle: AngleType | number;
  startCoordinates?: number[];
  coordinates: number[];
  waypoints: EnhancedWaypoint[];
}

export enum AngleType {
  LOW = 3,
  MEDIUM = 6,
  HIGH = 9,
}
