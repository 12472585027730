import { observer } from "mobx-react-lite";
import { Platform, ScrollView, useWindowDimensions } from "react-native";
import { useBrandTheme } from "../../theme/use-brand-theme";
import React, { useMemo } from "react";
import {
  Chart,
  HorizontalAxis,
  VerticalAxis,
} from "react-native-responsive-linechart";
import { CARD_MARGIN } from "../../theme/view-style";
import { formatNumber } from "../../utils/formatNumber";
import { KilometerPerHour } from "../../domain/speed-converters/KilometerPerHour";
import { CHART_CONFIG } from "./graphs/RaceGraphs";

interface ChartProps {
  data: { x: number; y: number }[];
  isFullscreen: boolean;
  fullscreenWidth: number;
  verticalAxisFormatter: (v: number) => string;
  yDomain: { min: number; max: number };
  tickCount: number;
  lineChart: React.ReactNode;
  isLargeScreen: boolean;
}

export const RaceChart = observer(function RaceChart(props: ChartProps) {
  const {
    data,
    isFullscreen,
    verticalAxisFormatter,
    fullscreenWidth,
    yDomain,
    tickCount,
    lineChart,
    isLargeScreen,
  } = props;
  const preventStopScrollingForWeb = Platform.OS === "web";
  const layout = useWindowDimensions();
  const theme = useBrandTheme();
  const moduloIfManyPoints = data.length > 100 ? 10 : 5;

  const widgetWidth = isLargeScreen ? layout.width / 2 : layout.width;

  const memo = useMemo(() => {
    return (
      <ScrollView
        horizontal={true}
        scrollEnabled={preventStopScrollingForWeb || isFullscreen}
      >
        {/* @ts-ignore */}
        <Chart
          disableGestures={true}
          style={{
            height: CHART_CONFIG.graphHeight,
            width: widgetWidth - CARD_MARGIN + fullscreenWidth,
          }}
          data={data}
          padding={CHART_CONFIG.chartPadding}
          yDomain={yDomain}
        >
          <VerticalAxis
            tickCount={tickCount}
            theme={{
              labels: {
                formatter: (v) => verticalAxisFormatter(v),
                label: {
                  color: theme.colors.text,
                },
              },
              grid: {
                stroke: {
                  color: theme.colors.border,
                },
              },
            }}
          />
          <HorizontalAxis
            tickCount={moduloIfManyPoints}
            theme={{
              labels: {
                formatter: (v) =>
                  formatNumber(KilometerPerHour.fromMeterPerHour(v), 1),
                label: {
                  color: theme.colors.text,
                },
              },
              grid: {
                stroke: {
                  color: theme.colors.border,
                },
              },
            }}
          />
        </Chart>
        {lineChart}
      </ScrollView>
    );
  }, [data, widgetWidth, isFullscreen, theme.dark]);

  return memo;
});
