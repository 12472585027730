import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import {
  Platform,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from "react-native";
import { getPercentColor } from "../../../services/getPercentColor";
import { Text } from "../../Text";
import { formatNumber } from "../../../utils/formatNumber";
import { KilometerPerHour } from "../../../domain/speed-converters/KilometerPerHour";
import { MinPerKilometer } from "../../../domain/speed-converters/MinPerKilometer";
import { Speed } from "../../../domain/race-calculator/Speed";
import { TimeConverter } from "../../../domain/race-calculator/TimeConverter";
import {
  CARD_MARGIN,
  scrollTableCell,
  scrollTableRow,
  SMALL_BUTTON,
} from "../../../theme/view-style";
import { AppDarkTheme, palette, spacing } from "../../../theme";
import { Button } from "../../Button";
import { translate } from "swunitch-i18n";
import { RaceTableProps } from "./RaceTable";
import { Foundation } from "@expo/vector-icons";
import { useBrandTheme } from "../../../theme/use-brand-theme";
import { Tag } from "../../Tag";

export const RaceRowTable = observer(function RaceRowTable(
  props: RaceTableProps & { showWaypointsOnly: boolean },
) {
  const { segments, onSegmentSelected, showWaypointsOnly } = props;
  const isOnPacevisorWebsite =
    Platform.OS === "web" && window.location.hostname === "pacevisor.com";
  const [showAll, setShowAll] = useState<boolean>(
    isOnPacevisorWebsite ? true : props.showAll,
  );
  const layout = useWindowDimensions();
  const theme = useBrandTheme();

  const tableElements = useMemo(
    () =>
      segments
        .filter((_, index) => {
          if (showAll) {
            return true;
          }
          return index < 5;
        })
        .map((segment, index) => {
          const _5km: number | undefined = Object.values(
            segment.lapTimes || {},
          )[0];
          const _10km: number | undefined = Object.values(
            segment.lapTimes || {},
          )[1];
          const color = getPercentColor(segment.percentAngle);

          return (
            <React.Fragment key={index}>
              {segment.waypoints.map((waypoint, index) => {
                return (
                  <TouchableOpacity
                    key={index}
                    style={{
                      backgroundColor: theme.colors.info,
                    }}
                    onPress={() => onSegmentSelected(segment)}
                  >
                    <View style={[scrollTableRow, { borderBottomWidth: 0 }]}>
                      <Text preset="bold" style={scrollTableCell(0.8)}>
                        {formatNumber(
                          KilometerPerHour.fromMeterPerHour(waypoint.distance),
                        )}
                      </Text>
                      <Text style={scrollTableCell(0.6)}>
                        {formatNumber(waypoint.elevationGain)}
                      </Text>
                      <Text style={scrollTableCell(0.6)}>
                        {formatNumber(waypoint.elevationLoss)}
                      </Text>
                      <Text style={scrollTableCell()}>
                        {formatNumber(waypoint.cumulativeElevationGain)}
                      </Text>
                      <Text style={scrollTableCell()}>
                        {formatNumber(waypoint.cumulativeElevationLoss)}
                      </Text>
                    </View>
                    <View
                      style={[
                        scrollTableRow,
                        {
                          padding: 0,
                          paddingHorizontal: spacing.small,
                          paddingBottom: spacing.tiny,
                          gap: spacing.extraSmall,
                        },
                      ]}
                    >
                      <View>
                        <Text>
                          {waypoint.title} (
                          <Foundation
                            name="mountains"
                            size={15}
                            color={theme.colors.text}
                          />{" "}
                          {translate("raceScreen.elevation", {
                            value: formatNumber(waypoint.altitude),
                          })}
                          )
                        </Text>
                      </View>
                      {waypoint.tags.map((tag, index) => (
                        <Tag
                          key={index}
                          style={{ backgroundColor: palette.secondary400 }}
                        >
                          <Text style={{ color: AppDarkTheme.colors.text }}>
                            {tag}
                          </Text>
                        </Tag>
                      ))}
                    </View>
                  </TouchableOpacity>
                );
              })}
              {!showWaypointsOnly && (
                <TouchableOpacity
                  style={[
                    scrollTableRow,
                    color ? { backgroundColor: color } : {},
                  ]}
                  onPress={() => onSegmentSelected(segment)}
                >
                  <Text preset="bold" style={scrollTableCell(0.8)}>
                    {formatNumber(
                      KilometerPerHour.fromMeterPerHour(segment.distance),
                    )}
                  </Text>
                  <Text style={scrollTableCell(0.6)}>
                    {formatNumber(segment.elevationGain)}
                  </Text>
                  <Text style={scrollTableCell(0.6)}>
                    {formatNumber(segment.elevationLoss)}
                  </Text>
                  <Text style={scrollTableCell()}>
                    {formatNumber(segment.cumulativeElevationGain)}
                  </Text>
                  <Text style={scrollTableCell()}>
                    {formatNumber(segment.cumulativeElevationLoss)}
                  </Text>
                  <Text style={scrollTableCell()}>
                    {MinPerKilometer.fromMeterPerHour(
                      Speed.of(segment.speed).getSpeedWithEffort(),
                    )}
                  </Text>
                  <Text style={scrollTableCell()}>
                    {formatNumber(
                      KilometerPerHour.fromMeterPerHour(segment.speed),
                    )}
                  </Text>
                  <Text style={scrollTableCell(1.5)}>
                    {TimeConverter.toHuman(
                      TimeConverter.fromSecondsToDomain(segment.cumulativeTime),
                    )}
                  </Text>
                  <Text style={scrollTableCell()}>
                    {_5km &&
                      TimeConverter.toHuman(
                        TimeConverter.fromSecondsToDomain(_5km),
                      )}
                  </Text>
                  <Text style={scrollTableCell(1.5)}>
                    {_10km &&
                      TimeConverter.toHuman(
                        TimeConverter.fromSecondsToDomain(_10km),
                      )}
                  </Text>
                </TouchableOpacity>
              )}
            </React.Fragment>
          );
        }),
    [segments, showAll, showWaypointsOnly, theme.dark],
  );

  return (
    <>
      {tableElements}

      {showAll ? null : (
        <View
          style={{
            width: layout.width - CARD_MARGIN,
            padding: spacing.extraSmall,
          }}
        >
          <Button
            preset="outlined"
            style={SMALL_BUTTON}
            onPress={() => setShowAll(!showAll)}
          >
            <Text>{translate("raceScreen.seeMore")}</Text>
          </Button>
        </View>
      )}
    </>
  );
});
