import { View } from "react-native";
import { Mask } from "react-native-mask-input";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Converter } from "./Converter";
import { Text } from "../../../Text";
import {
  BIG_CONVERTER,
  BIG_INPUT,
  BIG_INPUT_TEXT,
  INPUT_SELECTED,
} from "../../../../theme/view-style";
import { useBrandTheme } from "../../../../theme/use-brand-theme";

interface EditableConverterProps {
  onValueChange: (value: string) => void;
  value: string;
  mask?: Mask;
  testID?: string;
  disabled?: boolean;
}

export const EditableConverter = observer(function EditableConverter(
  props: EditableConverterProps,
) {
  const { mask, onValueChange, value, testID, disabled = false } = props;
  const [editable, setEditable] = useState(false);
  const theme = useBrandTheme();
  const isInEdition = editable && !disabled;

  return (
    <View style={[BIG_INPUT(theme), disabled ? INPUT_SELECTED : null]}>
      {isInEdition ? (
        <Converter
          formattedValue={value}
          onValueChange={onValueChange}
          mask={mask}
          style={BIG_CONVERTER}
          onBlur={() => setEditable(false)}
        />
      ) : (
        <Text
          style={BIG_INPUT_TEXT}
          testID={testID}
          {...(disabled ? {} : { onPress: () => setEditable(true) })}
        >
          {value}
        </Text>
      )}
    </View>
  );
});
