import { observer } from "mobx-react-lite";
import { AppDarkTheme, palette, spacing } from "../../theme";
import { View } from "react-native";
import { ImageBackground } from "expo-image";
import { Button, Card, imageRegistry, Text } from "../../components";
import { SMALL_BUTTON, SMALL_BUTTON_TEXT } from "../../theme/view-style";
import React, { useEffect, useState } from "react";
import { translate } from "swunitch-i18n";
import { ListRaces } from "./ListRaces";
import { LocationService } from "../../services/refreshNearMeLocation";
import { useStores } from "../../models";

export const NearByRaces = observer(function NearByRaces() {
  const { globalStore } = useStores();
  const [hasLocation, setHasLocation] = useState(
    !!globalStore.latitude && !!globalStore.longitude,
  );

  useEffect(() => {
    LocationService.isLocationEnabled().then((permission) => {
      if (permission.granted) {
        getLocation();
      }
    });
  }, []);

  useEffect(() => {
    globalStore.fetchNearbyRaces();
  }, [globalStore.latitude, globalStore.longitude]);

  const getLocation = async () => {
    LocationService.refreshNearMeLocation((latitude, longitude) => {
      setHasLocation(true);
      globalStore.setNearMeLocation(latitude, longitude);
    });
  };

  return (
    <View>
      {hasLocation && (
        <ListRaces
          title={translate("runScreen.nearBy")}
          races={globalStore.nearbyRaces}
          computeTime={false}
          carousel={true}
        />
      )}
      {!hasLocation && (
        <Card
          onPress={() => getLocation()}
          style={{
            backgroundColor: palette.neutral900,
            marginTop: 0,
          }}
        >
          <ImageBackground
            source={imageRegistry.blueBackground}
            style={{
              justifyContent: "center",
              alignItems: "center",
              padding: spacing.extraSmall,
            }}
          >
            <Text
              preset="subheading"
              style={{ color: AppDarkTheme.colors.text, textAlign: "center" }}
              tx="runScreen.geolocationTitle"
            />
            <Text
              size="xxs"
              style={{ color: AppDarkTheme.colors.text, textAlign: "center" }}
              tx="runScreen.geolocationDescription"
            />
            <Button
              onPress={() => getLocation()}
              preset="reversed"
              style={[SMALL_BUTTON, { marginTop: spacing.small }]}
              textStyle={SMALL_BUTTON_TEXT}
              tx="runScreen.geolocationButton"
            />
          </ImageBackground>
        </Card>
      )}
    </View>
  );
});
