import { useBrandTheme } from "../../../theme/use-brand-theme";
import { useWindowDimensions } from "react-native";
import { getPercentColor } from "../../../services/getPercentColor";
import { LineChart } from "react-native-wagmi-charts";
import { Stop } from "react-native-svg";
import { CARD_MARGIN } from "../../../theme/view-style";
import React from "react";
import { CHART_CONFIG, RaceGraphsProps } from "./RaceGraphs";
import * as shape from "d3-shape";
import { palette } from "../../../theme";

export function PacevisorLineChart({
  graph,
  profile,
  race,
  isLargeScreen,
  fullscreenWidth,
}: {
  graph: "profile" | "segments";
  fullscreenWidth: number;
} & RaceGraphsProps) {
  const theme = useBrandTheme();
  const layout = useWindowDimensions();
  const widgetWidth = isLargeScreen ? layout.width / 2 : layout.width;
  const color = theme.colors.text;

  const specific =
    graph === "profile" ? (
      <>
        {profile.map((segment, index) => {
          const color = getPercentColor(segment.percentAngle);
          if (color) {
            return (
              <LineChart.Highlight
                from={index}
                to={index + 1}
                key={index}
                color={color}
                width={2}
              />
            );
          }
          return null;
        })}

        {race.waypoints.map((w) => {
          const waypoint = profile.findIndex(
            (segment) => segment.distance >= w.distance,
          );
          if (waypoint === -1) {
            return null;
          }

          return (
            <LineChart.Dot
              at={waypoint}
              key={w.distance}
              color={palette.secondary400}
              size={6}
            />
          );
        })}

        {
          <LineChart.Gradient color={color}>
            <Stop offset="10%" stopColor={color} stopOpacity={0.7} />
            <Stop offset="30%" stopColor={color} stopOpacity={0.5} />
            <Stop offset="70%" stopColor={color} stopOpacity={0.1} />
            <Stop offset="100%" stopColor={color} stopOpacity={0} />
          </LineChart.Gradient>
        }
      </>
    ) : null;

  return (
    <LineChart
      height={CHART_CONFIG.graphHeight}
      width={
        widgetWidth -
        CHART_CONFIG.chartPadding.left -
        CHART_CONFIG.chartPadding.right -
        CARD_MARGIN +
        fullscreenWidth
      }
      shape={shape.curveMonotoneX}
    >
      <LineChart.Path
        color={color}
        pathProps={{
          width: 2,
        }}
      >
        {specific}
      </LineChart.Path>
      <LineChart.CursorLine snapToPoint={true} minDurationMs={150} />
      <LineChart.HoverTrap />
    </LineChart>
  );
}
