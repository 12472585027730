import React, { ComponentType } from "react";
import {
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewStyle,
} from "react-native";
import { useBrandTheme } from "../theme/use-brand-theme";
import {
  CARD,
  CARD_CONTAINER,
  CARD_NO_SHADOW,
  CARD_WITH_BORDER,
} from "../theme/view-style";
import { spacing } from "../theme";

interface CardProps {
  children?: React.ReactNode;
  style?: ViewStyle;
  paddingVertical?: boolean | number;
  paddingHorizontal?: boolean | number;
  isLast?: boolean;
  onPress?: () => void;
  noShadow?: boolean;
  withBorder?: boolean;
}

export function Card(props: CardProps) {
  const {
    children,
    style,
    paddingVertical,
    paddingHorizontal,
    isLast,
    noShadow,
    withBorder,
  } = props;
  const theme = useBrandTheme();

  const Wrapper = (
    props.onPress ? TouchableOpacity : View
  ) as ComponentType<TouchableOpacityProps>;

  return (
    <Wrapper
      style={[
        CARD_CONTAINER(theme),
        isLast ? { paddingBottom: 0 } : {},
        noShadow ? CARD_NO_SHADOW(theme) : {},
        withBorder ? CARD_WITH_BORDER(theme) : {},
        style,
      ]}
      onPress={props.onPress}
    >
      <View
        style={[
          CARD(theme),
          !paddingVertical
            ? {}
            : {
                paddingVertical:
                  typeof paddingVertical === "number"
                    ? paddingVertical
                    : spacing.medium,
              },
          !paddingHorizontal
            ? {}
            : {
                paddingHorizontal:
                  typeof paddingHorizontal === "number"
                    ? paddingHorizontal
                    : spacing.medium,
              },
        ]}
      >
        {children}
      </View>
    </Wrapper>
  );
}
