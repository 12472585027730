import React, { useState } from "react";
import { TextStyle, View, ViewStyle } from "react-native";
import { observer } from "mobx-react-lite";
import { AppDarkTheme, spacing } from "../../theme";
import { Race } from "../../models/race/race";
import { Feather } from "@expo/vector-icons";
import { translate } from "swunitch-i18n";
import { Platform } from "expo-modules-core";
import { recordEvent } from "../../services/plausible";
import * as Sharing from "expo-sharing";
import * as FileSystem from "expo-file-system";
import { openUrl } from "../../services/openUrl";
import { Button } from "../Button";
import { useBrandTheme } from "../../theme/use-brand-theme";

export const RaceCTA = observer(function RaceCTA(props: { race: Race }) {
  const { race } = props;
  const theme = useBrandTheme();
  const [gpxLoading, setGpxLoading] = useState(false);

  const style: ViewStyle = {
    borderRadius: 15,
    paddingHorizontal: spacing.extraSmall,
    borderColor: theme.colors.primary,
    flexGrow: 1,
  };
  const textStyle: TextStyle = {
    color: theme.colors.primary,
    fontSize: 14,
  };

  function openLink() {
    if (!race.url) return null;

    recordEvent(`races/${race.id}`, "open-website");

    return openUrl(race.url);
  }

  async function openGpx() {
    if (race.gpxUrl) {
      recordEvent(`races/${race.id}`, "open-gpx");

      if (Platform.OS === "web") {
        openUrl(race.gpxUrl);
      } else {
        const gpxName = race.gpxUrl.split("/").pop();
        const localUri = FileSystem.cacheDirectory + gpxName!;
        await FileSystem.downloadAsync(race.gpxUrl, localUri);
        await Sharing.shareAsync(localUri, { mimeType: "application/gpx+xml" });
      }
    }
  }

  return (
    <View style={{ padding: spacing.extraSmall }}>
      <View
        style={{
          flexDirection: "row",
          gap: spacing.extraSmall,
        }}
      >
        {race.gpxUrl ? (
          <Button
            loading={gpxLoading}
            onPress={() => {
              setGpxLoading(true);
              openGpx().finally(() => setGpxLoading(false));
            }}
            preset="reversed"
            style={[
              style,
              {
                backgroundColor: theme.colors.primary,
              },
            ]}
            textStyle={[
              textStyle,
              {
                color: AppDarkTheme.colors.text,
              },
            ]}
          >
            {translate("raceScreen.gpxFile")} <Feather name="download" />
          </Button>
        ) : null}

        {!!race.url && (
          <Button
            onPress={openLink}
            preset="outlined"
            style={style}
            textStyle={textStyle}
          >
            {translate("raceScreen.seeWebsite")}{" "}
            <Feather name="external-link" />
          </Button>
        )}
      </View>
    </View>
  );
});
