import React, { useMemo } from "react";
import { Foundation, MaterialCommunityIcons } from "@expo/vector-icons";
import { TimeConverter } from "../../../domain/race-calculator/TimeConverter";
import { translate } from "swunitch-i18n";
import { formatNumber } from "../../../utils/formatNumber";
import { KilometerPerHour } from "../../../domain/speed-converters/KilometerPerHour";
import { RaceType } from "../../../models/race/race-type";
import { getPaceUnit, getSpeedUnit } from "../../../utils/humanUnit";
import { MinPerKilometer } from "../../../domain/speed-converters/MinPerKilometer";
import { Speed } from "../../../domain/race-calculator/Speed";
import { View } from "react-native";
import { spacing } from "../../../theme";
import { CENTER_MIDDLE } from "../../../theme/view-style";
import { Text } from "../../Text";
import { observer } from "mobx-react-lite";
import { useBrandTheme } from "../../../theme/use-brand-theme";
import { EnhancedSegment } from "../../../services/analysis/GeoJsonEnhancer";
import { useStores } from "../../../models";

interface MetricsProps {
  selectedSegment: EnhancedSegment;
  type: RaceType;
  actualDistance?: number;
}

export const Metrics = observer(function Metrics(props: MetricsProps) {
  const { selectedSegment, type, actualDistance } = props;
  const theme = useBrandTheme();
  const { globalStore } = useStores();

  const metrics = useMemo(() => {
    const color = theme.colors.text;

    return [
      [
        {
          icon: (
            <MaterialCommunityIcons
              name="clock-outline"
              size={15}
              color={color}
            />
          ),
          text: TimeConverter.toHuman(
            TimeConverter.fromSecondsToDomain(selectedSegment.cumulativeTime),
          ),
        },
        {
          icon: (
            <MaterialCommunityIcons
              name="arrow-left-right"
              size={15}
              color={color}
            />
          ),
          text: translate("units.kilometer", {
            count: formatNumber(
              KilometerPerHour.fromMeterPerHour(
                actualDistance === undefined
                  ? selectedSegment.distance
                  : actualDistance,
              ),
            ),
          }),
        },
        type === RaceType.Bike
          ? {
              icon: (
                <MaterialCommunityIcons
                  name="speedometer"
                  size={15}
                  color={color}
                />
              ),
              text: translate("raceScreen.pace", {
                value: formatNumber(
                  KilometerPerHour.fromMeterPerHour(selectedSegment.speed),
                ),
                unit: getSpeedUnit(globalStore.isMetricSystem),
              }),
            }
          : {
              icon: (
                <MaterialCommunityIcons
                  name="speedometer-medium"
                  size={15}
                  color={color}
                />
              ),
              text: translate("raceScreen.pace", {
                value: MinPerKilometer.fromMeterPerHour(
                  Speed.of(selectedSegment.speed).getSpeedWithEffort(),
                ),
                unit: getPaceUnit(globalStore.isMetricSystem),
              }),
            },
      ],
      [
        {
          icon: <Foundation name="mountains" size={15} color={color} />,
          text: translate("raceScreen.elevation", {
            value: formatNumber(selectedSegment.altitude),
          }),
        },
        {
          icon: (
            <MaterialCommunityIcons
              name="arrow-top-right"
              size={15}
              color={color}
            />
          ),
          text: translate("raceScreen.elevation", {
            value: formatNumber(selectedSegment.elevationGain),
          }),
        },
        {
          text: formatNumber(selectedSegment.percentAngle) + "%",
          icon: (
            <MaterialCommunityIcons
              name="angle-acute"
              size={15}
              color={color}
            />
          ),
        },
      ],
    ].map((list, index) => (
      <View
        key={index}
        style={{
          flexDirection: "row",
          gap: spacing.small,
          rowGap: 0,
          flexWrap: "wrap",
        }}
      >
        {list.map((item, index) => (
          <View
            style={{
              ...CENTER_MIDDLE,
              gap: spacing.micro,
            }}
            key={index}
          >
            {item.icon}
            <Text
              preset="bold"
              size="xs"
              style={{
                color,
              }}
            >
              {item.text}
            </Text>
          </View>
        ))}
      </View>
    ));
  }, [
    type,
    selectedSegment,
    globalStore.isMetricSystem,
    theme.dark,
    actualDistance,
  ]);

  return <>{metrics}</>;
});
