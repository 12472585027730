import * as turf from "@turf/turf";
import { Feature, LineString } from "@turf/turf";
import { EnhancedSegment } from "../../services/analysis/GeoJsonEnhancer";
import { FeatureCollection, Geometry } from "@turf/helpers";
import { PositionHistory } from "./Ghost";

export interface RunnerLocation {
  segment: EnhancedSegment;
  segmentIndex: number;
  nearestPoint: number[];
  distanceFromStart: number;
}

export class RunnerTracker {
  private enhancedSegments: EnhancedSegment[];
  private route: Feature<LineString>;

  constructor(
    geoJson: FeatureCollection<Geometry>,
    enhancedSegments: EnhancedSegment[],
  ) {
    this.enhancedSegments = enhancedSegments;
    this.route = geoJson.features[0] as Feature<LineString>;
  }

  public locateRunner(
    currentPosition: turf.helpers.Position,
    lastKnownDistance = 0,
  ): RunnerLocation {
    const currentPoint = turf.point(currentPosition);
    const nearestPoint = turf.nearestPointOnLine(this.route, currentPoint, {
      units: "meters",
    });
    const lineToSnappedPoint = turf.lineSlice(
      this.route.geometry.coordinates[0],
      nearestPoint,
      this.route,
    );
    const distance = turf.length(lineToSnappedPoint, { units: "meters" });

    let closestSegment = this.enhancedSegments[0];
    let closestIndex = -1;
    let smallestDistance = Infinity;

    for (let i = 0; i < this.enhancedSegments.length; i++) {
      const segment = this.enhancedSegments[i];

      if (
        segment.distance - segment.length < smallestDistance &&
        segment.distance >= lastKnownDistance
      ) {
        smallestDistance = distance;
        closestSegment = segment;
        closestIndex = i;
      }
    }
    return {
      segment: closestSegment,
      segmentIndex: closestIndex,
      nearestPoint: nearestPoint.geometry.coordinates,
      distanceFromStart: distance,
    };
  }

  public computeSpeedFromHistory(
    positionHistory: PositionHistory[],
  ): number | null {
    if (positionHistory.length < 2) {
      return null;
    }

    const totalSpeed = positionHistory.reduce(
      (sum, position) => sum + position.speed,
      0,
    );
    const averageSpeed = totalSpeed / positionHistory.length;
    return averageSpeed;
  }
}
