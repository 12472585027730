import React, { useEffect, useState } from "react";
import { Platform, View } from "react-native";
import { observer } from "mobx-react-lite";
import { EnhancedSegment } from "../../services/analysis/GeoJsonEnhancer";
import {
  Metadata,
  MetadataPoint,
} from "../../services/analysis/GeoJsonMetadata";
import { ExpoLeaflet } from "expo-leaflet";
import * as Device from "expo-device";
import { DeviceType } from "expo-device";
import { ExpoLeafletProps } from "expo-leaflet/web/src/ExpoLeaflet.types";
import { Button } from "../Button";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { palette } from "../../theme";
import { EnhancedWaypoint } from "../../services/analysis/GeoJsonSegmenter";
import { RunnerLocation } from "../ghost/RunnerTracker";
import { KilometerPerHour } from "../../domain/speed-converters/KilometerPerHour";
import { formatNumber } from "../../utils/formatNumber";

interface RaceMapProps {
  selectedSegment: EnhancedSegment;
  metadata: Metadata | null;
  profile: EnhancedSegment[];
  countryCode: string;
  onMoveStart: () => void;
  onMoveEnd: () => void;
  runnerPosition: RunnerLocation | null;
  isFullscreen: boolean;
  onFullscreen: (f: boolean) => void;
}

export const EXPO_LEAFLET_PROPS = (
  countryCode: string,
): Pick<ExpoLeafletProps, "mapLayers" | "mapOptions"> => ({
  mapLayers: [
    {
      attribution:
        '<a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      baseLayerIsChecked: true,
      baseLayerName: "OpenStreetMap",
      layerType: "TileLayer",
      url:
        countryCode === "FR"
          ? "https://data.geopf.fr/wmts?LAYER=GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2&EXCEPTIONS=text/xml&FORMAT=image/png&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&TILEMATRIX={z}&TILECOL={x}&TILEROW={y}"
          : "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      baseLayer: true,
    },
  ],
  mapOptions: {
    scrollWheelZoom: false,
    zoomControl: Platform.OS === "web",
    dragging: !(
      Platform.OS === "web" && Device.deviceType === DeviceType.PHONE
    ),
  },
});

export const RaceMap = observer(function RaceMap(props: RaceMapProps) {
  const {
    selectedSegment,
    metadata,
    onMoveEnd,
    onMoveStart,
    profile,
    runnerPosition,
    countryCode,
    isFullscreen,
    onFullscreen,
  } = props;
  const [marker, setMarker] = useState<Omit<MetadataPoint, "distance">>({
    lat: 0,
    lon: 0,
  });
  const [waypoints, setWaypoints] = useState<EnhancedWaypoint[]>([]);
  const [shape, setShape] = useState<[number, number][]>([]);
  const [km, setKm] = useState<EnhancedSegment[]>([]);

  useEffect(() => {
    if (
      selectedSegment.distance === 0 &&
      profile[0] &&
      profile[0].startCoordinates
    ) {
      setMarker({
        lat: profile[0].startCoordinates[1],
        lon: profile[0].startCoordinates[0],
      });
      return;
    }

    const foundSegment = profile.find((i) => {
      return i.distance >= selectedSegment.distance;
    });
    const lastSegment: EnhancedSegment | undefined =
      profile[profile.length - 1];

    const found: Omit<MetadataPoint, "distance"> = foundSegment
      ? {
          lat: foundSegment.coordinates[1],
          lon: foundSegment.coordinates[0],
        }
      : lastSegment
        ? {
            lat: lastSegment.coordinates[1],
            lon: lastSegment.coordinates[0],
          }
        : {
            lat: 0,
            lon: 0,
          };

    setMarker(found);
  }, [selectedSegment, profile]);

  useEffect(() => {
    const bounds: [number, number][] =
      metadata?.points.map((i) => {
        return [i.lat, i.lon];
      }) || [];
    setShape(bounds);
  }, [metadata]);

  useEffect(() => {
    setWaypoints(
      profile.flatMap((segment) => {
        return segment.waypoints;
      }),
    );
  }, [profile]);

  useEffect(() => {
    if (metadata) {
      const eachKm =
        metadata.totalLength > 100 ? 5 : metadata.totalLength > 50 ? 4 : 2;
      setKm(
        profile.filter((segment) => {
          return (
            KilometerPerHour.fromMeterPerHour(segment.distance) % eachKm === 0
          );
        }),
      );
    }
  }, [profile, metadata]);

  const toggleFullscreen = () => {
    onFullscreen(!isFullscreen);
  };

  const expoLeaflet = metadata ? (
    <ExpoLeaflet
      {...EXPO_LEAFLET_PROPS(countryCode)}
      mapCenterPosition={{
        lat: metadata.center.lat,
        lng: metadata.center.lon,
      }}
      zoom={Platform.OS === "web" ? undefined : 13}
      onMessage={(e) => {
        if (e.tag === "onMoveStart") {
          onMoveStart();
        } else if (e.tag === "onMoveEnd") {
          onMoveEnd();
        }
      }}
      mapMarkers={[
        ...km.map((segment, index) => ({
          position: {
            lat: segment.coordinates[1],
            lng: segment.coordinates[0],
          },
          id: `km-${index}`,
          icon: `
<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" style="position: relative; bottom: 7px;">
  <circle cx="12" cy="12" r="7" fill="white" stroke="${palette.map}" stroke-width="2" />
  <text x="50%" y="50%" text-anchor="middle" alignment-baseline="middle" font-size="8" fill="black">
    ${formatNumber(KilometerPerHour.fromMeterPerHour(segment.distance))}
  </text>
</svg>`,
        })),
        {
          position: {
            lat: marker.lat,
            lng: marker.lon,
          },
          id: "1",
          icon: `
<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" style="position: relative; bottom: 7px;">
  <circle cx="12" cy="12" r="7" fill="white" stroke="${palette.neutral900}" stroke-width="2" />
</svg>`,
          size: [5, 5],
        },
        ...(runnerPosition
          ? [
              {
                position: {
                  lat: runnerPosition.nearestPoint[1],
                  lng: runnerPosition.nearestPoint[0],
                },
                id: "2",
                icon: `
<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" style="position: relative; bottom: 7px;">
  <circle cx="12" cy="12" r="7" fill="white" stroke="${palette.map}" stroke-width="2" />
</svg>`,
                size: [5, 5] as [number, number],
              },
            ]
          : []),
        ...waypoints.map((waypoint, index) => {
          const tag = waypoint.icon || "";
          const circle = tag
            ? `<circle cx="20" cy="25" r="15" fill="white" stroke="${palette.secondary400}" stroke-width="4" />`
            : `<circle cx="20" cy="30" r="10" fill="${palette.secondary400}" stroke="${palette.secondary400}" stroke-width="4" />`;
          let title = waypoint.title;
          if (waypoint.tags.length > 0) {
            title += ` (${waypoint.tags.join(", ")})`;
          }

          return {
            position: {
              lat: waypoint.coordinates[1],
              lng: waypoint.coordinates[0],
            },
            id: `waypoint-${index}`,
            title,
            icon: `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 50" width="30" height="40" style="position: relative; bottom: 35px;">
    <path d="M20 50 L15 35 L25 35 Z" fill="${palette.secondary400}"/>
    
    ${circle}
    
    <text x="20" y="30" text-anchor="middle" font-size="20" dy="2">${tag}</text>
  </svg>`,
          };
        }),
      ]}
      mapShapes={[
        {
          shapeType: "polyline",
          color: palette.map,
          id: "5",
          positions: shape,
        },
      ]}
    />
  ) : null;
  return (
    <View
      style={{
        height: isFullscreen ? 600 : 400,
      }}
    >
      {isFullscreen ? expoLeaflet : null}
      {!isFullscreen ? expoLeaflet : null}

      <Button
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          zIndex: 1000,
          borderWidth: 1,
        }}
        preset="rounded"
        onPress={toggleFullscreen}
        RightAccessory={() => (
          <MaterialCommunityIcons
            name={isFullscreen ? "fullscreen-exit" : "fullscreen"}
            size={25}
            color={palette.neutral900}
          />
        )}
      />
    </View>
  );
});
