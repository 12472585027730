import React from "react";
import { View } from "react-native";
import { observer } from "mobx-react-lite";
import { useBrandTheme } from "../theme/use-brand-theme";
import {
  CENTER_MIDDLE,
  RACE_ANALYSIS_COMPONENT_COLORS,
} from "../theme/view-style";
import { Card } from "./Card";
import { AppDarkTheme, spacing } from "../theme";
import { Text } from "./Text";
import { MaterialCommunityIcons } from "@expo/vector-icons";

interface CardToggleProps {
  title: string;
  description?: string;
  value: boolean;
  onToggle: () => void;
}

export const CardToggle = observer(function CardToggle({
  title,
  description,
  value,
  onToggle,
}: CardToggleProps) {
  const theme = useBrandTheme();
  return (
    <Card
      onPress={onToggle}
      noShadow
      withBorder
      paddingVertical={spacing.small}
      paddingHorizontal={spacing.small}
      style={{
        backgroundColor: RACE_ANALYSIS_COMPONENT_COLORS.dark3,
        borderWidth: 3,
        ...(value ? { borderColor: theme.colors.primary } : {}),
      }}
    >
      <View style={CENTER_MIDDLE}>
        <View
          style={{
            flex: 1,
          }}
        >
          <Text
            preset="formLabel"
            style={{
              color: AppDarkTheme.colors.text,
            }}
          >
            {title}
          </Text>
          {description ? (
            <Text
              size="xs"
              style={{
                color: AppDarkTheme.colors.text,
                marginTop: spacing.extraSmall,
              }}
            >
              {description}
            </Text>
          ) : null}
        </View>
        <View>
          {value ? (
            <MaterialCommunityIcons
              name="check-circle"
              size={30}
              color={theme.colors.primary}
            />
          ) : (
            <MaterialCommunityIcons
              name="circle-outline"
              size={30}
              color={AppDarkTheme.colors.text}
            />
          )}
        </View>
      </View>
    </Card>
  );
});
