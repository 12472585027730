import React, { FC, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../models";
import { Sport } from "../../models/sport/sport";
import { AppStackScreenProps } from "../../navigators";
import { useBrandTheme } from "../../theme/use-brand-theme";
import {
  BACKGROUND_CONTAINER,
  BOTTOM_SHEET_TEXT,
  CENTER_MIDDLE,
  CONTAINER,
  scrollTable,
  scrollTableCell,
  scrollTableRow,
} from "../../theme/view-style";
import { palette, spacing } from "../../theme";
import { StyleSheet, View, ViewStyle } from "react-native";
import { ImageBackground } from "expo-image";
import { useOrientation } from "../../utils/orientation-style";
import { imageRegistry, Screen, Text } from "../../components";
import { ScrollTable } from "../../components/ScrollTable";
import { Distance } from "../../domain/race-calculator/Distance";
import { DistanceSize } from "../../domain/race-calculator/DistanceSize";
import { TimeConverter } from "../../domain/race-calculator/TimeConverter";
import { Speed } from "../../domain/race-calculator/Speed";
import { MAS } from "../../services/MAS";
import { KilometerPerHour } from "../../domain/speed-converters/KilometerPerHour";
import { MinPerKilometer } from "../../domain/speed-converters/MinPerKilometer";
import { translate } from "swunitch-i18n";
import { formatNumber } from "../../utils/formatNumber";
import { MASEdition } from "../../components/sports/converters/MASEdition";
import Badge from "@react-navigation/bottom-tabs/src/views/Badge";
import { Slider } from "@miblanchard/react-native-slider";

const distances = [
  { distance: Distance.of(5, DistanceSize.long), flex: 1.4 },
  { distance: Distance.of(10, DistanceSize.long), flex: 1.4 },
  { distance: Distance.of(21.0975, DistanceSize.long), flex: 1.4 },
  { distance: Distance.of(42.195, DistanceSize.long), flex: 1.4 },
  { distance: Distance.of(50, DistanceSize.long), flex: 1.4 },
  { distance: Distance.of(80, DistanceSize.long), flex: 1.4 },
];

function percentColor(
  percentFromSpeed: number | null,
  idealPercent: number | null,
): string {
  const range = MAS.percentColor(percentFromSpeed, idealPercent);

  switch (range) {
    case "easy":
      return palette.easy;
    case "medium":
      return palette.low;
    case "hard":
      return palette.high;
    default:
      return "";
  }
}

function formatMas(percent: number | null): string {
  return percent ? ` (${formatNumber(percent, 0)}%)` : "";
}

const legendStyle: ViewStyle = {
  ...CENTER_MIDDLE,
  gap: spacing.extraSmall,
};

const masTableStyle: ViewStyle = {
  ...scrollTable,
  minWidth: 1100,
};

export const MasTableScreen: FC<AppStackScreenProps<"mas-table">> = observer(
  function MasTableScreen() {
    const { globalStore } = useStores();
    const sport: Sport = useMemo(() => {
      return globalStore.sport.run;
    }, []);
    const deviceOrientation = useOrientation();
    const theme = useBrandTheme();

    const [startSpeed, _setStartSpeed] = useState(7);
    const [endSpeed, _setEndSpeed] = useState(18);
    const [range, setRange] = useState(0.2);
    const speeds = Array.from(
      { length: Math.ceil((endSpeed - startSpeed) / range) + 1 },
      (_, index) => startSpeed + index * range,
    )
      .map((speed) => Speed.of(speed * 1000))
      .reverse();

    const percents = distances.map((distance) =>
      MAS.getPercentFromDistance(sport.aerobicCapacity, distance.distance),
    );

    return (
      <Screen preset="scroll">
        <View
          style={{
            ...BACKGROUND_CONTAINER(theme),
            gap: spacing.large,
          }}
        >
          <View style={{ paddingVertical: spacing.small }}>
            <ImageBackground
              source={imageRegistry.blueBackground}
              style={{
                ...StyleSheet.absoluteFillObject,
              }}
            />

            <View
              style={[CONTAINER(deviceOrientation), { gap: spacing.medium }]}
            >
              <MASEdition sport={sport} />

              <View>
                <Text preset="subheading" style={BOTTOM_SHEET_TEXT}>
                  {translate("masTable.range")} ({formatNumber(range)})
                </Text>
                <Slider
                  containerStyle={{ height: 30 }}
                  minimumValue={0.1}
                  maximumValue={1}
                  trackClickable={true}
                  step={0.1}
                  value={range}
                  onValueChange={(v) => {
                    if (v[0] !== undefined) {
                      setRange(v[0]);
                    }
                  }}
                  minimumTrackTintColor={theme.colors.primary}
                  maximumTrackTintColor={palette.neutral900 + "66"}
                  thumbTintColor={theme.colors.primary}
                  // @ts-ignore
                  testID="slider"
                />
              </View>
            </View>
          </View>

          <View style={CONTAINER(deviceOrientation)}>
            <View style={[legendStyle, { gap: spacing.medium }]}>
              <View style={legendStyle}>
                <Badge
                  visible={true}
                  style={{
                    backgroundColor: palette.easy,
                    alignSelf: "flex-start",
                  }}
                />
                <Text size="xs" preset="formHelper" tx="masTable.legend.easy" />
              </View>
              <View style={legendStyle}>
                <Badge
                  visible={true}
                  style={{
                    backgroundColor: palette.low,
                    alignSelf: "flex-start",
                  }}
                />
                <Text
                  size="xs"
                  preset="formHelper"
                  tx="masTable.legend.medium"
                />
              </View>
              <View style={legendStyle}>
                <Badge
                  visible={true}
                  style={{
                    backgroundColor: palette.high,
                    alignSelf: "flex-start",
                  }}
                />
                <Text size="xs" preset="formHelper" tx="masTable.legend.hard" />
              </View>
            </View>
          </View>

          <ScrollTable>
            <View style={masTableStyle}>
              <View style={scrollTableRow}>
                <Text
                  preset="bold"
                  tx="masTable.kmh"
                  style={scrollTableCell(0.8)}
                />
                <Text
                  preset="bold"
                  tx="masTable.min-km"
                  style={scrollTableCell(0.8)}
                />
                <Text preset="bold" style={scrollTableCell(distances[0].flex)}>
                  {translate("units.kilometer", {
                    count: 5,
                  })}
                  {formatMas(percents[0])}
                </Text>
                <Text preset="bold" style={scrollTableCell(distances[1].flex)}>
                  {translate("units.kilometer", {
                    count: 10,
                  })}
                  {formatMas(percents[1])}
                </Text>
                <Text preset="bold" style={scrollTableCell(distances[2].flex)}>
                  {translate("category.halfMarathon")}
                  {formatMas(percents[2])}
                </Text>
                <Text preset="bold" style={scrollTableCell(distances[3].flex)}>
                  {translate("category.marathon")}
                  {formatMas(percents[3])}
                </Text>
                <Text preset="bold" style={scrollTableCell(distances[4].flex)}>
                  {translate("units.kilometer", {
                    count: 50,
                  })}
                  {formatMas(percents[4])}
                </Text>
                <Text preset="bold" style={scrollTableCell(distances[5].flex)}>
                  {translate("units.kilometer", {
                    count: 80,
                  })}
                  {formatMas(percents[5])}
                </Text>
              </View>
              {speeds.map((speed) => {
                const times = distances.map((distance) =>
                  TimeConverter.toDomain(
                    distance.distance.getInSmallSize(),
                    speed.getSpeedWithEffort(),
                  ),
                );

                const percentFromSpeed =
                  (speed.getSpeedWithEffort() * 100) / sport.aerobicCapacity;

                return (
                  <View style={scrollTableRow} key={speed.getSpeedWithEffort()}>
                    <Text style={scrollTableCell(0.8)}>
                      {KilometerPerHour.fromMeterPerHour(
                        speed.getSpeedWithEffort(),
                      ).toFixed(1)}
                    </Text>
                    <Text style={scrollTableCell(0.8)}>
                      {MinPerKilometer.fromMeterPerHour(
                        speed.getSpeedWithEffort(),
                      )}
                    </Text>
                    {percents.map((percent, index) => (
                      <Text
                        key={index}
                        style={[
                          scrollTableCell(distances[index].flex),
                          {
                            backgroundColor: percentColor(
                              percentFromSpeed,
                              percent,
                            ),
                          },
                        ]}
                      >
                        {TimeConverter.toHuman(times[index])}

                        {percentColor(percentFromSpeed, percent) !== "" ? (
                          <Text size="xxs">
                            {" "}
                            ({formatNumber(percentFromSpeed, 1)}%)
                          </Text>
                        ) : null}
                      </Text>
                    ))}
                  </View>
                );
              })}
            </View>
          </ScrollTable>
        </View>
      </Screen>
    );
  },
);
